import {
  GET_REFERRAL_STATS_REQUEST,
  GET_REFERRAL_STATS_SUCCESS,
  GET_REFERRAL_STATS_ERROR,
} from '../../types/types';

export default function ReferralStats(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_REFERRAL_STATS_REQUEST:
      return {
        loading: true,
      };
    case GET_REFERRAL_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case GET_REFERRAL_STATS_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    default:
      return state;
  }
}
