import {
    AUTHENTICATE,
  } from "../../types/types";
  
  export default function getValidate(state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case AUTHENTICATE:
        return {
          ...state,
          loading: false,
          payload: payload,
        };
      default:
        return state;
    }
  }
  