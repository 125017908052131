import React, { useState } from "react";
import { Select, Tabs } from "antd";

import DashboardGrid from "../../components/Dashboard/dashboard.grid";

const { Option } = Select;
const handleChange = (value) => {
  
};


const { TabPane } = Tabs;

export default function Dashboard() {
  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);
  const [gridActive, setGridActive] = useState(true);
  const [eventTitle, setEventTitle] = useState(
    searchParams.get("type") === null ? "event" : searchParams.get("type")
  );
  const [eventStatus, setEventStatus] = useState(123);
  const callbackTabClicked = (key, event) => {
    const url = new URL(window.location.href);
    url.searchParams.set("type", key);
    window.history.replaceState(null, null, url);
    setEventTitle(key);
    setEventStatus(event.timeStamp);
  };

  return (
    <>
      {/* <div className="grid-listing-area flex-column  flex-sm-row align-items-start align-items-sm-center mb-30">
        <div className="grid-listing-left flex-grow-1">
          <Tabs
            // defaultActiveKey="Upcoming"
            defaultActiveKey={eventTitle}
            // onChange={onChange}
            className="events-tabs-main"
            onTabClick={callbackTabClicked}
          >
            <TabPane tab="Event Details" key="event"></TabPane>
          </Tabs>
        </div>
      </div> */}

      <DashboardGrid
        className={gridActive}
        title={eventTitle}
        eventStatus={eventStatus}
      />
    </>
  );
}
