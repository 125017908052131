import { GET_REFERRAL_SUMMARY } from "../../types/types";
export default function summaryReferral(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_REFERRAL_SUMMARY:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    default:
      return state;
  }
}