import {
  DISCONNECT_INSTAGRAM_REQUEST,
  DISCONNECT_INSTAGRAM_SUCCESS,
  DISCONNECT_INSTAGRAM_ERROR,
} from '../../types/types';

export default function disconnectInstagram(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case DISCONNECT_INSTAGRAM_REQUEST:
      return {
        loading: true,
      };
    case DISCONNECT_INSTAGRAM_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case DISCONNECT_INSTAGRAM_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    default:
      return state;
  }
}
