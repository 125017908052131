import {
  POST_STOPLIVE_REQUEST,
  POST_STOPLIVE_SUCCESS,
  POST_STOPLIVE_ERROR,
} from "../../types/types";

export default function stopLive(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case POST_STOPLIVE_REQUEST:
      return {
        loading: true,
      };
    case POST_STOPLIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    case POST_STOPLIVE_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    default:
      return state;
  }
}
