import {
    UPLOADS3_REVIEW, UPLOAD_DONE_REVIEW,
} from '../../types/types';

export default function uploadInfo(state = [], action) {
    const { type, payload } = action;
    let data = [...state]
    switch (type) {
        case UPLOADS3_REVIEW:
            data.push(payload)
            return data
        case UPLOAD_DONE_REVIEW:
            return data.filter(f => f.id !== payload.id);
        default:
            return state;
    }
}
    