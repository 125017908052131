import {
  GET_ALL_CREATOR_REQUEST,
  GET_ALL_CREATOR_SUCCESS,
  GET_ALL_CREATOR_ERROR,
} from "../../types/types";

export default function getAllCreatorData(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_CREATOR_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_CREATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    case GET_ALL_CREATOR_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    default:
      return state;
  }
}
