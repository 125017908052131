import { ALL_PRODUCT_SEARCH_SKU_AND_TITLE } from "../../types/types";


export default function getInventoryProductsBySkuAndTitle(state = [], action) {
  switch (action.type) {
    case ALL_PRODUCT_SEARCH_SKU_AND_TITLE:
      return action.payload
    default:
      return state;
  }
}
