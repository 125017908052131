import React, { useEffect, useState } from "react";
import Router from './routing/routes';
import axios from 'axios';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './fonts/flaticon/_flaticon.scss';
import './scss/custom.scss';
import './css/generic.scss';
import './css/analytics.scss';
import "./css/home.scss";
import "./css/createEvent.scss";
import "./css/accounttype.scss";
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { notification } from 'antd';
import { AUTHENTICATE } from './redux/types/types';

const history = createBrowserHistory({ forceRefresh: true });

const getPublicIP = () => {
  return new Promise((resolve, reject) => {
    const pc = new RTCPeerConnection({
      iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] // STUN server URL
    });
    pc.createDataChannel('');
    pc.createOffer().then(sdp => {
      pc.setLocalDescription(sdp);
    }).catch(error => {
      reject(error);
    });
    pc.onicecandidate = event => {
      if (event.candidate) {
        const candidate = event.candidate.candidate;
        const regex = /([0-9]{1,3}(\.[0-9]{1,3}){3})/;
        const match = regex.exec(candidate);
        if (match) {
          resolve(match[0]);
          pc.close();
        }
      }
    };
  });
};

const App = () => {
  let notificationAlreadyShown = false;
  useEffect(() => {
    getPublicIP().then(ip => {
      axios.defaults.headers.common['public-ip'] = ip ? ip : "";
    }).catch(error => console.error("Error obtaining public IP:", error));
  }, []);
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if ((error.response?.status === 406 || error.response?.status === 401) && !notificationAlreadyShown) {
        notification.error({
          message: 'Session Expired!!',
          className: 'toast-error',
        });
        notificationAlreadyShown = true;
        localStorage.removeItem('token');
        localStorage.removeItem('Role');
        store.dispatch({ type: AUTHENTICATE, payload: {} })
        // history.push("/login") 
        // window.location.href = "/login" 
      } else {
        return Promise.reject(error);
      }
    }
  );

  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
};
export default App;
