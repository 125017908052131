import { GET_PAYPAL_LIMIT_REQUEST, GET_PAYPAL_LIMIT_SUCCESS, GET_PAYPAL_LIMIT_ERROR } from "../../types/types";
export default function contact(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_PAYPAL_LIMIT_REQUEST:
            return {
                loading: true,
            };
        case GET_PAYPAL_LIMIT_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: payload,
            };
        case GET_PAYPAL_LIMIT_ERROR:
            return {
                ...state,
                loading: false,
                payload: payload,
            };
        default:
            return state;
    }
}