import {
  SAVE_CONTROL_REQUEST,
  SAVE_CONTROL_SUCCESS,
  SAVE_CONTROL_ERROR,
} from "../../types/types";

export default function SaveControl(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_CONTROL_REQUEST:
      return {
        loading: true,
      };
    case SAVE_CONTROL_SUCCESS:  
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    case SAVE_CONTROL_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    default:
      return state;
  }
}
