import React, { createContext, useState } from "react";
const Percent = createContext(undefined);
const SetPercent = createContext(undefined);
function PercentProvider({ children }) {
    const [percent, setPercent] = useState({});
    function setter(val) {
        setPercent((prev) => ({ ...prev, ...val }))
    }
    return (
        <Percent.Provider value={percent}>
            <SetPercent.Provider value={setter}>
                {children}
            </SetPercent.Provider>
        </Percent.Provider>
    );
}

export { PercentProvider, Percent, SetPercent };