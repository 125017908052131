import {
	GET_FEE_STRUCTURE_REQUEST,
	GET_FEE_STRUCTURE_SUCCESS,
	GET_FEE_STRUCTURE_ERROR,
} from "../../types/types";
export default function getStructureFees(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_FEE_STRUCTURE_REQUEST:
			return {
				loading: true,
			};
		case GET_FEE_STRUCTURE_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case GET_FEE_STRUCTURE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}