import { AGREEMENT } from "../../types/types";
export default function getAgreementRes(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case AGREEMENT:
            return {
                ...state,
                payload: payload,
            };
        default:
            return state;
    }
}