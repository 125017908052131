import {
	GET_CHAT_LOG_REQUEST, GET_CHAT_LOG_SUCCESS, GET_CHAT_LOG_ERROR
} from "../../types/types";

export default function getChatLog(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_CHAT_LOG_REQUEST:
			return {
				loading: true,
			};
		case GET_CHAT_LOG_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case GET_CHAT_LOG_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}