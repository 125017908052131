import {
  LINK_INFLUENCER_PRODUCT_REQUEST,
  LINK_INFLUENCER_PRODUCT_SUCCESS,
  LINK_INFLUENCER_PRODUCT_ERROR
} from "../../types/types";

export default function linkInfluencerVideo(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case LINK_INFLUENCER_PRODUCT_REQUEST:
      return {
        loading: true,
      };
    case LINK_INFLUENCER_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case LINK_INFLUENCER_PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    default:
      return state;
  }
}
