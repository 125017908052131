import { GET_URL_REQUEST, GET_URL_SUCCESS, GET_URL_ERROR } from "../../types/types";
export default function shareUrls(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_URL_REQUEST:
			return {
				loading: true,
			};
		case GET_URL_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case GET_URL_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}