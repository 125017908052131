import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { Spin, Alert } from "antd";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import "../../css/login.scss";
import logo from "../../images/ormelogo.svg";
import { putResetPassword } from "../../redux/actions/forgotPassword.action";

export default function ResetPassword() {
  const dispatch = useDispatch();
  const params = useParams();
  const token = params?.id;

  const [spinner, setSpin] = useState(false);

  const [success, setSuccess] = useState(false);

  const onFinish = (values) => {
    setSpin(true);
    dispatch(putResetPassword(token, values.password)).then((res) => {
      if (res.payload.success) {
        setSpin(false);
        setSuccess(true)
      } else {
        setSpin(false);
        setSuccess(false)
      }
    });
  };
  const onFinishFailed = (errorInfo) => {
  };

  return (
    <>
      <div className="login-main">
        <div className="login-left">
          <div className="login-left-content">
            <div className="intro-main">
              <h1>Introducing</h1>
              <h2>LIVE SHOPPING</h2>
              <p>
                Boost your sales, create authentic and engaging relationships
                with your customers, in an innovative way!
              </p>
            </div>
          </div>
        </div>
        <div className="login-right">
          <div className="login-right-content form-styling">
            <div className="logo-login">
              <img src={logo} alt="logo" />
            </div>
            {!success ? <>
              <h2 className="login-hd mb-10">Reset Password</h2>

              <Form
                name="basic"
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" size="large" />
                </Form.Item>

                <Form.Item
                  name="retypePassword"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "The two passwords that you entered do not match!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Retype Password" size="large" />
                </Form.Item>

                <Form.Item>
                  <Spin spinning={spinner}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="default-btn login-btn reset-btn w-100"
                    >
                      Reset Password
                    </Button>
                  </Spin>
                </Form.Item>
              </Form>

            </>
              : <Alert
                message="Password Reset Successfully"
                type="success"
                showIcon
                className="mb-3"
              />
            }

            <Link className="signin-link" to="/login">
              Sign In
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
