import {
    CONNECT_STRIPE,
  } from "../../types/types";
  
  export default function stripeConnect(state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case CONNECT_STRIPE:
        return {
          ...state,
          loading: false,
          payload: payload,
        };
      default:
        return state;
    }
  }
  