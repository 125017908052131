import {
    POST_CUSTOM_PRODUCT_REQUEST,
    POST_CUSTOM_PRODUCT_SUCCESS,
    POST_CUSTOM_PRODUCT_ERROR
} from "../../types/types";
export default function addCustomProducts(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case POST_CUSTOM_PRODUCT_REQUEST:
            return {
                loading: true,
            };
        case POST_CUSTOM_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: payload,
            };
        case POST_CUSTOM_PRODUCT_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };
        default:
            return state;
    }
}