import { POST_BRAND_REGISTER_REQUEST, POST_BRAND_REGISTER_SUCCESS, POST_BRAND_REGISTER_ERROR } from "../../types/types";
export default function brandRegister(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case POST_BRAND_REGISTER_REQUEST:
			return {
				loading: true,
			};
		case POST_BRAND_REGISTER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case POST_BRAND_REGISTER_ERROR:
			return {
				...state,
				loading: false,
				payload: payload ? payload : [],
			};

		default:
			return state;
	}
}