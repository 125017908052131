import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Image, InputNumber, Modal, notification, Spin } from "antd";
import { Button } from "react-bootstrap";
import paypalLogo from "../../images/paypal-logo.jpg";
import { redirectURL } from "../../config";
import PaymentMethod from "../paymentMethod";
import { connectStripe } from "../../redux/actions/connectStripe.action";
import { connectPaypal } from "../../redux/actions/connectPaypal.action";
import { validateUser } from "../../redux/actions/login.action";
import PaypalSetup from "./paypalSetup";

export default () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const token = JSON.parse(localStorage.getItem("token"));
  const { validate } = useSelector(
    (state) => {
      return state;
    }
  );

  useEffect(() => {
    let query = new URL(window.location.href);
    let searchParams = new URLSearchParams(query.search);
    if (searchParams?.get("error")) {
      notification.error({
        message: searchParams?.get("error"),
        className: "toast-error",
      });
    }
    if (searchParams?.get("code")) {
      setLoader(true)
      console.log(searchParams?.get("code"));
      dispatch(connectPaypal(searchParams?.get("code"))).then((response) => {
        console.log(response);
        if (response?.success) {
          notification.success({
            message: response.message,
            className: "toast-success",
          });
        }
        query.search = '';
        window.history.replaceState(null, null, query.toString());
        dispatch(validateUser(token));
        console.log(validate);
        setLoader(false)
      })
    }

  }, [])

  const ProceedToPaypal = () => {
    // setLoading(true);
    const redirectUri = `${redirectURL}/payout`;
    const loginUrl = `${process.env.REACT_APP_PAYPALLINK}webapps/auth/protocol/openidconnect/v1/authorize?client_id=${process.env.REACT_APP_CLIENTID}&response_type=code&scope=openid%20profile%20email&redirect_uri=${redirectUri}`;
    window.location.href = loginUrl;
  }
  return (
    <>
      {!validate?.payload?.data?.paypal_email && validate?.payload?.data?.paypal_email !== "" ?
        <div className="containerd p-0 ml-0">
          <div className="row">
            <div className="col-lg-6 col-xl-4">
              <div class="title-area">
                <h1 class="page-title">Payment Setup</h1>
              </div>
              <div className="stream-box-white">
                {!loader ? (
                  <>
                    <div class="data-listing-box d-flex align-items-center flex-column">
                      <Image preview={false} className="mb-4 p-4" width={250} src={paypalLogo} />
                      <h4 class="count-title mb-4 text-center">Please click the button below to be redirected to PayPal's site where you can securely log in and connect your account.</h4>

                      <Button
                        className="default-btn padt-4 w-initial m-0"
                        onClick={ProceedToPaypal}
                      >
                        Connect
                      </Button>

                    </div>
                  </>
                ) : (
                  <span className="spinclass loader-center position-relative">
                    <Spin size="large" />
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        :
        <PaypalSetup validate={validate} />
      }
    </>
  );
};
