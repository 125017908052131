import { GET_ALL_SHIPPING_REQUEST,GET_ALL_SHIPPING_SUCCESS,GET_ALL_SHIPPING_ERROR  } from "../../types/types";

const initialState = {};  
  export default function getAllShipping(state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case GET_ALL_SHIPPING_REQUEST:
        return {
          loading: true,
        };
      case GET_ALL_SHIPPING_SUCCESS:
        return {
          ...state,
          loading: false,
          payload: payload,
        };
  
      case GET_ALL_SHIPPING_ERROR:
        return {
          ...state,
          loading: false,
          payload: payload,
        };
  
      default:
        return state;
    }
  }
  