import {
    GET_DISCOUNT_REQUEST,
    GET_DISCOUNT_SUCCESS,
    GET_DISCOUNT_ERROR,
} from "../../types/types";
export default function getDiscountList(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_DISCOUNT_REQUEST:
            return {
                loading: true,
            };
        case GET_DISCOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: payload,
            };
        case GET_DISCOUNT_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };
        default:
            return state;
    }
}