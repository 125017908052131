import { GET_INSTA_REELS } from "../../types/types";

const initialState = []

export default function instaReels(state = initialState, action) {
  switch (action.type) {
    case GET_INSTA_REELS:
      return action.payload
    default:
      return state;
  }
}
