import {
    DELETE_STREAM_REQUEST,
    DELETE_STREAM_SUCCESS,
    DELETE_STREAM_ERROR,
  } from "../../types/types";
  
  export default function deleteStream(state = "", action) {
    const { type, payload } = action;
    switch (type) {
      case DELETE_STREAM_REQUEST:
        return {
          loading: true,
          success: true,
        };
      case DELETE_STREAM_SUCCESS:
        return {
          ...state,
          loading: false,
          success: false,
          payload: payload,
        };
  
      case DELETE_STREAM_ERROR:
        return {
          ...state,
          loading: false,
          success: false,
          payload: payload,
        };
  
      default:
        return state;
    }
  }
  