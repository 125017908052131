import { SHOPIFY_CUSTOMER_ORDER } from "../../types/types";
export default function shopifyCustomerorder(state = "", action) {
	const { type, payload } = action;
	switch (type) {
			case SHOPIFY_CUSTOMER_ORDER:
				return {
					...state,
					loading: false,
					payload: payload,
				};
		default:
			return state;
	}
}
