import { POST_CONTACT_REQUEST, POST_CONTACT_SUCCESS, POST_CONTACT_ERROR } from "../../types/types";
export default function contact(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case POST_CONTACT_REQUEST:
            return {
                loading: true,
            };
        case POST_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: payload,
            };
        case POST_CONTACT_ERROR:
            return {
                ...state,
                loading: false,
                payload: payload,
            };
        default:
            return state;
    }
}