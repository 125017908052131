import { PAYPAL_TRANSACTION_REPORT_REQUEST, PAYPAL_TRANSACTION_REPORT_SUCCESS, PAYPAL_TRANSACTION_REPORT_ERROR } from "../../types/types";
export default function contact(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case PAYPAL_TRANSACTION_REPORT_REQUEST:
            return {
                loading: true,
            };
        case PAYPAL_TRANSACTION_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: payload,
            };
        case PAYPAL_TRANSACTION_REPORT_ERROR:
            return {
                ...state,
                loading: false,
                payload: payload,
            };
        default:
            return state;
    }
}