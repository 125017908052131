import { GET_STREAM_REQUEST, GET_STREAM_SUCCESS, GET_STREAM_ERROR } from "../../types/types";
export default function getAllStream(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_STREAM_REQUEST:
			return {
				loading: true,
			};
		case GET_STREAM_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case GET_STREAM_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}