import { HIT_INSTA_API } from "../../types/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case HIT_INSTA_API:
			return {
				...state,
				payload
			};
	
		default:
			return state;
	}
}
