import {
  faBagShopping,
  faCalendar,
  faList,
  faMoneyCheckDollar,
  faPercent,
  faPercentage,
  faRecordVinyl,
  faTelevision,
  faUsd,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, DatePicker, Spin, Tabs } from "antd";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../css/dashboard.scss";
import { getDashboard } from "../../redux/actions/dashboard.action";

const { RangePicker } = DatePicker;
const dateFormat = "MM-DD-YYYY";
const { TabPane } = Tabs;

let groupKey = "";
export default function EventDetail() {
  const dispatch = useDispatch();
  const limit = 8;
  const [loading, setLoading] = useState(false);
  // const fromDate = moment().startOf('month').format('YYYY-MM-DD');
  // const toDate = moment(new Date()).format('YYYY-MM-DD');
  const toDate = moment(new Date()).format("YYYY-MM-DD");
  const fromDate = moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD");
  const [parents, setParent] = useState("");
  const [groupBy, setGroupby] = useState("");
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);

  const { dashboardPayloadSocial } = useSelector((state) => {
    return state;
  });

  useEffect(() => {
    setLoading(true);

    dispatch(getDashboard(fromDate, toDate)).then((res) => {
      setLoading(false);
    });
  }, []);

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString?.[0];
    const endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleSubmit = (e) => {
    setLoading(true);
    dispatch(getDashboard(
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD")
    )).then((res) => {
      setLoading(false);
    });
  };

  const handleReset = () => {
    // setStartDate(moment().startOf('month').format('YYYY-MM-DD'));
    // setEndDate(moment(new Date()).format('YYYY-MM-DD'));
    setLoading(true);
    setStartDate(fromDate);
    setEndDate(toDate);
    dispatch(getDashboard(fromDate, toDate)).then((res) => {
      setLoading(false);
    });
  };

  return (
    <>
      {/* <div className="home-intro-box-main">
        No Content
      </div> */}
      <div className="container-fluid ml-0 p-0">
        <div className="row w-20-desktop-main">
          <div className="col-xl-3 col-md-6 col-12 mb-25">
            <p className="mb-5rem">Select Start Date / End Date</p>
            <RangePicker
              size="large"
              className="w-100"
              key={4}
              value={
                startDate && endDate ? [moment(startDate), moment(endDate)] : []
              }
              allowClear={false}
              ranges={{
                // Today: [moment(), moment()],
                // Tomorrow: [
                //   moment().add(1, 'days'),
                //   moment().add(1, 'days'),
                // ],
                // Yesterday: [
                //   moment().subtract(1, 'days'),
                //   moment().subtract(1, 'days'),
                // ],
                "All Time": [
                  moment().subtract(1, "year").startOf("year"),
                  moment(),
                ],
                "YTD": [moment().startOf("year"), moment()],
                "Last 30 Days": [moment().subtract(30, "days"), moment()],
                "Last 7 Days": [moment().subtract(7, "days"), moment()],
                "Today": [moment(), moment()],
                // "Last Year": [
                //   moment().subtract(1, "year").startOf("year"),
                //   moment().subtract(1, "year").endOf("year"),
                // ],
                // "This Year": [moment().startOf("year"), moment().endOf("year")],
                // "Last Month": [
                //   moment().subtract(1, "month").startOf("month"),
                //   moment().subtract(1, "month").endOf("month"),
                // ],
                // "This Month": [
                //   moment().startOf("month"),
                //   moment().endOf("month"),
                // ],
              }}
              format={dateFormat}
              onChange={dateRangePickerChanger}
            />
          </div>

          <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-25">
            <Button
              onClick={handleSubmit}
              className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Search
            </Button>

            <Button
              onClick={handleReset}
              className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Reset
            </Button>
          </div>
        </div>
      </div>

      <div className="container-fluid ml-0 p-0 dashboard-page">
        {/* Agency Fee Earned */}
        <div className="row">
          <div className="col-12 mb-2">
            <div className="title-area pb-10">
              <h1 className="title-hd">Earnings</h1>
            </div>
            {/* <h5 className="mb-0">Brand</h5> */}
          </div>
        </div>

        <div className="row">
          <div className="col-6 col-xl col-lg-3 col-md-6">
            <div className="dashboard-info-box">
              <FontAwesomeIcon
                className="info-icon-main"
                icon={faUsd}
              />
              <div class="value-info-hd">
                {loading ? (
                  <Spin size="small" />
                ) : (
                  <div>
                    {numeral(
                      dashboardPayloadSocial?.payload?.data?.creator_commission
                    ).format("$0,0.0'")}
                  </div>
                )}
              </div>
              <div class="total-info-hd">Creator Fee</div>
            </div>
          </div>
          <div className="col-6 col-xl col-lg-3 col-md-6">
            <div className="dashboard-info-box">
              <FontAwesomeIcon
                className="info-icon-main"
                icon={faUsd}
              />
              <div class="value-info-hd">
                {loading ? (
                  <Spin size="small" />
                ) : (
                  <div>
                    {numeral(
                      dashboardPayloadSocial?.payload?.data?.creator_agency_commsion
                    ).format("$0,0.0'")}
                  </div>
                )}
              </div>
              <div class="total-info-hd">Creator Agency Fee</div>
            </div>
          </div>
          <div className="col-6 col-xl col-lg-3 col-md-6">
            <div className="dashboard-info-box">
              <FontAwesomeIcon className="info-icon-main" icon={faUsd} />
              <div class="value-info-hd">
                {loading ? (
                  <Spin size="small" />
                ) : (
                  <div>
                    {numeral(
                      dashboardPayloadSocial?.payload?.data?.customer_commission
                    ).format("$0,0.0'")}
                  </div>
                )}
              </div>
              <div class="total-info-hd">Customer Fee</div>
            </div>
          </div>
          <div className="col-6 col-xl col-lg-3 col-md-6">
            <div className="dashboard-info-box">
              <FontAwesomeIcon className="info-icon-main" icon={faUsd} />
              <div class="value-info-hd">
                {loading ? (
                  <Spin size="small" />
                ) : (
                  <div>
                    {numeral(
                      dashboardPayloadSocial?.payload?.data?.ref_agency_commission
                    ).format("$0,0.0'")}
                  </div>
                )}
              </div>
              <div class="total-info-hd">Referral Agency Fee</div>
            </div>
          </div>
          <div className="col-6 col-xl col-lg-3 col-md-6">
            <div className="dashboard-info-box">
              <FontAwesomeIcon className="info-icon-main" icon={faUsd} />
              <div class="value-info-hd">
                {loading ? (
                  <Spin size="small" />
                ) : (
                  <div>
                    {numeral(
                      dashboardPayloadSocial?.payload?.data?.totalAgencyFee
                    ).format("$0,0.0'")}
                  </div>
                )}
              </div>
              <div class="total-info-hd">Total Agency Fee</div>
            </div>
          </div>


        </div>

        <div className="separator-dashboard"></div>
        {/* Total sales */}
        <div className="row">
          <div className="col-12 mb-2">
            <div className="title-area pb-10 ">
              <h1 className="title-hd">Total Sales</h1>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6 col-xl col-lg-3 col-md-6 laptop-width-bo">
            <div className="dashboard-info-box">
              <FontAwesomeIcon className="info-icon-main" icon={faList} />
              <div class="value-info-hd">
                {loading ? (
                  <Spin size="small" />
                ) : (
                  <div>
                    {numeral(
                      dashboardPayloadSocial?.payload?.data?.summary?.[0]?.order_count
                    ).format("0")}
                  </div>
                )}
              </div>
              <div class="total-info-hd"># Of Orders</div>
            </div>
          </div>
          <div className="col-6 col-xl col-lg-3 col-md-6">
            <div className="dashboard-info-box">
              <FontAwesomeIcon
                className="info-icon-main"
                icon={faUsd}
              />
              <div class="value-info-hd">
                {loading ? (
                  <Spin size="small" />
                ) : (
                  <div>
                    {numeral(
                      dashboardPayloadSocial?.payload?.data?.summary?.[0]?.gross_amount
                    ).format("$0,0.0'")}
                  </div>
                )}
              </div>
              <div class="total-info-hd">Gross Sales</div>
            </div>
          </div>

          <div className="col-6 col-xl col-lg-3 col-md-6">
            <div className="dashboard-info-box">
              <FontAwesomeIcon className="info-icon-main" icon={faUsd} />
              <div class="value-info-hd">
                {loading ? (
                  <Spin size="small" />
                ) : (
                  <div>
                    {numeral(
                      dashboardPayloadSocial?.payload?.data?.summary?.[0]?.returned_amount
                    ).format("$0,0.0'")}
                  </div>
                )}
              </div>
              <div class="total-info-hd">Returned Amount</div>
            </div>
          </div>
          <div className="col-6 col-xl col-lg-3 col-md-6">
            <div className="dashboard-info-box">
              <FontAwesomeIcon className="info-icon-main" icon={faUsd} />
              <div class="value-info-hd">
                {loading ? (
                  <Spin size="small" />
                ) : (
                  <div>
                    {numeral(
                      dashboardPayloadSocial?.payload?.data?.summary?.[0]?.balance_amount
                    ).format("$0,0.0'")}
                  </div>
                )}
              </div>
              <div class="total-info-hd">Total Balance</div>
            </div>
          </div>
          <div className="col-6 col-xl col-lg-3 col-md-6">
            <div className="dashboard-info-box">
              <FontAwesomeIcon className="info-icon-main" icon={faUsd} />
              <div class="value-info-hd">
                {loading ? (
                  <Spin size="small" />
                ) : (
                  <div>
                    {numeral(
                      dashboardPayloadSocial?.payload?.data?.summary?.[0]?.order_totalprice
                    ).format("$0,0.0'")}
                  </div>
                )}
              </div>
              <div class="total-info-hd">Total Sales</div>
            </div>
          </div>


        </div>


      </div>

      {/* <div className="col-12 col-xl-3 col-lg-4 laptop-width-box">
            <div className="dashboard-box-main">
              <div className="box-top-area mb-30">
                <div className="box-top-hd-left">Total Gross Sales</div>
                <div className="box-top-hd-right">March 21, 08:00 PM</div>
              </div>
          

              <div className="box-bottom-area d-flex align-items-end justify-content-between">
                <div className="box-bottom-left d-flex justify-content-between w-100">
                  <div className="box-bottom-hd1">
                    112056
                  </div>
                  <div className="box-bottom-hd1 color-grey">
                    |
                  </div>
                  <div className="box-bottom-hd2">
                    <FontAwesomeIcon icon={faArrowTrendUp} /> +60% Today
                  </div>
                </div>



                <div className="box-top-hd-right">
                  
                </div>
              </div>



            </div>

          </div> */}

      {/* <div className="row">
          <div className="col-12 col-xl-8 col-lg-8 laptop-width-box2">
            <div className="filters-box-main">
              <div className="row filters-top-area d-flex align-items-center  mb-0">

                <div className='col-xl-4'>
                  <div className="filters-top-hd-left">Summary</div>
                  <div className="filters-top-hd-right">March 21, 08:00 PM</div>
                </div>

                <div className='col-xl-8'>

                  <div className="row">

                    <div className="col-12 col-md-9 mb-20">

                      <p>Select Start Date / End Date</p>
                      <RangePicker
                        size="large"
                        className="w-100"
                        key={4}
                        value={
                          startDate && endDate
                            ? [moment(startDate), moment(endDate)]
                            : []
                        }
                        allowClear={false}
                        ranges={{
                          Today: [moment(), moment()],
                          Tomorrow: [
                            moment().add(1, 'days'),
                            moment().add(1, 'days'),
                          ],
                          Yesterday: [
                            moment().subtract(1, 'days'),
                            moment().subtract(1, 'days'),
                          ],
                          'This Month': [
                            moment().startOf('month'),
                            moment().endOf('month'),
                          ],
                          'Last Month': [
                            moment().subtract(1, 'month').startOf('month'),
                            moment().subtract(1, 'month').endOf('month'),
                          ],
                        }}
                        format={dateFormat}
                        onChange={dateRangePickerChanger}
                      />
                    </div>

                    <div className="col-12 col-md-3  d-flex align-items-end mb-20">

                      <Button
                        onClick={handleSubmit}
                        className="default-btn fltr-btn m-0"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                      >
                        Search
                      </Button>

                    </div>
                  </div>

                </div>

              </div>
              <div className="filters-bottom-area">
                <div className="filters-data-main table-responsive scrollbar-style">
                  <table className="filters-data table text-nowrap table-stripe">
                    <thead className="table_heading">
                      <tr>
                        <th></th>
                        <th>YTD</th>
                        <th>MTD</th>
                        <th>Today</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Number of Events</td>
                        <td>
                          1500
                        </td>
                        <td>
                          1500
                        </td>
                        <td>
                          99
                        </td>
                      </tr>
                      <tr>
                        <td>Events Orders</td>
                        <td>
                          1500
                        </td>
                        <td>
                          1500
                        </td>
                        <td>
                          105
                        </td>
                      </tr>
                      <tr>
                        <td>Minutes Streamed</td>
                        <td>
                          1500
                        </td>
                        <td>
                          1500
                        </td>
                        <td>
                          190
                        </td>
                      </tr>
                      <tr>
                        <td>Gross Sales</td>
                        <td>
                          1500
                        </td>
                        <td>
                          1500
                        </td>
                        <td>
                          87
                        </td>
                      </tr>
                      <tr>
                        <td>Marketing Fees</td>
                        <td>
                          1500
                        </td>
                        <td>
                          1500
                        </td>
                        <td>
                          87
                        </td>
                      </tr>
                      <tr>
                        <td>Net Sales</td>
                        <td>
                          1500
                        </td>
                        <td>
                          1500
                        </td>
                        <td>
                          87
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-4 col-lg-4 laptop-width-box3">
            <div className="dashboard-box-main">
              <div className="box-top-area mb-30">

                <div>
                  <div className="box-top-hd-left">Summary</div>
                  <div className="box-top-hd-right">March 21, 08:00 PM</div>
                </div>

                <div>
                  <div className="total-hd events">Total Events</div>
                  <div className="total-hd sales">Total Sales</div>
                </div>

              </div>
              <div className="chart-style-main">
                <Chart />
              </div>
            </div>
          </div>

        </div> */}

    </>
  );
}
