import { EDIT_STREAM_REQUEST, EDIT_STREAM_SUCCESS, EDIT_STREAM_ERROR } from "../../types/types";
export default function editStream(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case EDIT_STREAM_REQUEST:
            return {
                loading: true,
            };
        case EDIT_STREAM_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: payload,
            };
        case EDIT_STREAM_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };

        default:
            return state;
    }
}
