import { BOOSTED_REVIEWS } from "../../types/types";
export default function getBoostedReviews(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case BOOSTED_REVIEWS:
            return {
                ...state,
                payload: payload,
            };
        default:
            return state;
    }
}