import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, InputNumber, Modal, notification, Spin, Image, Button, Tooltip, Tabs, Empty, Checkbox, Tag } from "antd";
import { connectStripe, stripeBalance } from "../../redux/actions/connectStripe.action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import numeral from "numeral";
import {
  faAngleLeft,
  faAngleRight,
  faUsd,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import paypalIcon from "../../images/paypal-icon.jpg";
import { validateUser } from "../../redux/actions/login.action";
import Swal from "sweetalert2";
import { disconnectPaypal, getPaypalLimit, paypalEarningSummary, paypalTransactionReport, paypalWithdraw } from "../../redux/actions/connectPaypal.action";
import { redirectURL } from "../../config";
import "../../css/sales.scss";
import moment from "moment";
import ReactPaginate from "react-paginate";

const { TabPane } = Tabs;
export default ({ validate }) => {
  const { confirm } = Modal;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [tableloading, setTableLoading] = useState(true);
  const [message, setMessage] = useState(true);
  const [balance, setBalance] = useState();
  const [activeTab, setActiveTab] = useState('All');
  const [currentPage, setCurrentPage] = useState(0);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [inputValue, setInputValue] = useState(null);
  const [isChecked, setIsChecked] = useState(false)
  const limit = 10;
  const [form] = Form.useForm();
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleSubmit = () => {
    if (inputValue <= totalWithdraw && inputValue <= maxamount && inputValue >= minamount) {
      Swal.fire({
        title: "Withdraw Amount",
        text: `Are you sure you want to Withdraw $${inputValue} ?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonColor: "#010b40",
        cancelButtonColor: "#d33",
        imageAlt: "Withdraw Amount",
        reverseButtons: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(paypalWithdraw({ withdraw_amount: inputValue })).then((res) => {
            Swal.fire({
              icon: "success",
              title: res?.payload?.message,
            });

            dispatch(paypalEarningSummary({ from_date: "", to_date: "", category: "all", gen_category: "all", sub_category: "all", brand: "all" })).then((res) => {
              // console.log(res);
            });
            dispatch(paypalTransactionReport(1, limit, { status: "All" })).then((res) => {
            });
          });
          setWithdrawModal(false);
        } else {
          // setWithdrawModal(false);
        }
      });
      setInputValue();
    } else if (inputValue > totalWithdraw) {
      Swal.fire({
        icon: "error",
        title: "Provide valid amount.",
      });
    } else if (inputValue < maxamount) {
      console.log(inputValue, maxamount);
      Swal.fire({
        icon: "error",
        title: "Invalid amount: Please enter a value between $25 and $5,000.",
      });
    }
  };

  const handleCancel = () => {
    setWithdrawModal(false)
  }

  const handlePageClick = (e) => {
    const page = e.selected;
    setCurrentPage(page);
    setTableLoading(true);
    dispatch(paypalTransactionReport(page + 1, limit, { status: activeTab })).then((res) => {
      setTableLoading(false)
    });
  };

  const token = JSON.parse(localStorage.getItem("token"));

  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);



  const { paypalEarnings, paypalLimit, paypalReport } = useSelector(
    (state) => {
      return state;
    }
  );

  const handleTabChange = (key) => {
    setTableLoading(true)
    setActiveTab(key);
    setCurrentPage(0);
    dispatch(paypalTransactionReport(1, limit, { status: key })).then((res) => {
      setTableLoading(false)
    });
  };

  useEffect(() => {
    let data = { from_date: "", to_date: "", category: "all", gen_category: "all", sub_category: "all", brand: "all" }
    dispatch(paypalEarningSummary(data)).then((res) => {
      // console.log(res);
    });
    dispatch(getPaypalLimit()).then((res) => {
      // console.log(res);
    });
    dispatch(paypalTransactionReport(1, limit, { status: activeTab })).then((res) => {
      setTableLoading(false)
    });

  }, []);

  const DisconnectPaypal = () => {
    // setLoading(true);
    Swal.fire({
      customClass: {
        icon: "paypal-confirm-modal",
      },
      title: `Are you sure you want to Disconnect paypal?`,
      iconHtml: `<img src=${paypalIcon}>`,
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: "#010b40",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(disconnectPaypal()).then((response) => {
          notification.success({
            message: response.message,
            className: "toast-success",
          });
          dispatch(validateUser(token));
        });
      } else {
      }
    });


  }
  const ConnectPaypal = () => {
    // setLoading(true);
    const redirectUri = `${redirectURL}/payout`;
    const loginUrl = `${process.env.REACT_APP_PAYPALLINK}webapps/auth/protocol/openidconnect/v1/authorize?client_id=${process.env.REACT_APP_CLIENTID}&response_type=code&scope=openid%20profile%20email&redirect_uri=${redirectUri}`;
    window.location.href = loginUrl;
  }

  function DefaultTable() {
    if (paypalReport?.payload?.data) {
      return (
        <>
          <div className="table-responsive scrollbar-style">
            <table className="transactions-box table">
              <thead className="table_heading">
                <tr>
                  <th className="td-width-60">S.#</th>
                  <th className="td-width-160">Date</th>
                  <th className="td-width-160">Amount</th>
                  <th className="td-width-160">Status</th>
                </tr>
              </thead>
              <tbody>
                {paypalReport?.payload?.data?.length === 0 ? (
                  <tr>
                    <td colspan="12">
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </td>
                  </tr>
                ) : (
                  paypalReport?.payload?.data?.map((item, i) => {
                    return (
                      <tr>
                        <td>{i + 1 + currentPage * limit}</td>
                        <td>{moment(item?.created_date).format("MM-DD-YYYY")}</td>
                        {item?.withdraw_amount ? (
                          <td>
                            $
                            {numeral(item?.withdraw_amount).format(
                              "0"
                            )}
                          </td>
                        ) : (
                          <td>{"-"}</td>
                        )}

                        {
                          item?.status === "Pending" ?
                            <td className="pt-2 pb-2"> <Tag color="orange">Pending</Tag></td>
                            : item?.status === "Processing" ?
                              <td className="pt-2 pb-2">  <Tag color="blue">Processing</Tag></td>
                              : item?.status === "release" ?
                                <td className="pt-2 pb-2">  <Tag color="blue">Releasing</Tag></td>
                                : item?.status === "Approved" ?
                                  <td className="pt-2 pb-2">  <Tag color="green">Approved</Tag></td>
                                  :
                                  <td className="pt-2 pb-2"> <Tag color="red">Rejected</Tag></td>
                        }
                        {/* <td>{item?.status ? item?.status : "-"}</td> */}
                        {/* <td>{item?.name ? item?.name : <Text type="secondary">Empty</Text>}</td>
                        <td>{item?.email ? item?.email : <Text type="secondary">Empty</Text>}</td>
                        <td>{item?.pixel_id}</td>
                        <td>{item?.phone}</td>
                        <td>{item?.is_active === true ? "Active" : "De-Active"}</td>
                        {
                          item?.agency?.status === "pending" ?
                            <td className="pt-2 pb-2"> <Tag color="orange">Pending</Tag></td>
                            : item?.agency?.status === "approved" ?
                              <td className="pt-2 pb-2">  <Tag color="green">Approved</Tag></td>
                              :
                              <td className="pt-2 pb-2"> <Tag color="red">Rejected</Tag></td>
                        } */}
                      </tr>
                    );
                  })
                )}
                {paypalReport?.payload?.data?.length > 0 && (
                  <tr>
                    <td>
                      <b>Total</b>
                    </td>
                    <td>
                      {/* {" "}
                      <b>
                        {paypalReport?.payload?.data?.length +
                          currentPage * limit}
                      </b> */}
                    </td>
                    <td>
                      <b>
                        {" "}
                        {numeral(
                          paypalReport?.payload?.data.reduce(
                            (a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.withdraw_amount
                                    ? b.withdraw_amount
                                    : 0.0
                                )
                              );
                            },
                            0
                          )
                        ).format("$0")}
                      </b>
                    </td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div >
        </>
      );
    }
  }

  const totalWithdraw =
    paypalEarnings?.payload?.message?.available_to_withdraw && (paypalEarnings?.payload?.message?.available_to_withdraw?.[0]?.cashback_amount +
      paypalEarnings?.payload?.message?.available_to_withdraw?.[0]?.creator_agency_commsion +
      paypalEarnings?.payload?.message?.available_to_withdraw?.[0]
        ?.ref_agency_commission -
      paypalEarnings?.payload?.message?.withdraw_amount);
  console.log(paypalEarnings);


  const totalEarning =
    paypalEarnings?.payload?.message?.pending_balance[0]?.cashback_amount +
    paypalEarnings?.payload?.message?.pending_balance[0]?.creator_agency_commission +
    paypalEarnings?.payload?.message?.pending_balance[0]?.ref_agency_commission;

  const LifetimeEarning =
    paypalEarnings?.payload?.message?.lifetime_earning[0]?.cashback_amount +
    paypalEarnings?.payload?.message?.lifetime_earning[0]?.creator_agency_commission +
    paypalEarnings?.payload?.message?.lifetime_earning[0]?.ref_agency_commission;

  const minamount = paypalLimit?.payload?.data?.min_amount;
  const maxamount = paypalLimit?.payload?.data?.max_amount;

  return (
    <>
      <div className="containerd p-0 ml-0">
        <div className="row">
          <div className="col-lg-12 col-xl-9">
            <div class="title-area">
              <h1 class="page-title">Paypal Setup</h1>
            </div>
            <div className="stream-box-white">
              {!loading ? (
                <>
                  <div class="data-listing-box pb-3 social-header-hd-area justify-content-start border-none">
                    {/* <h4 class="count-title align-items-center d-flex">{message}</h4> */}
                    <h4 class="count-title align-items-center d-flex">Your paypal account is {validate?.payload?.data?.paypal_email !== "" ? "successfully connected." : "disconnected."}</h4>
                    {
                      validate?.payload?.data?.paypal_email !== "" ?
                        <div className="connection-status-badge-green h-fit">Connected</div>
                        :
                        <div className="connection-status-badge-red h-fit">Disconnected</div>
                    }
                  </div>
                </>
              ) : (
                <span className="spinclass loader-center position-relative">
                  <Spin size="large" />
                </span>
              )}
              <div className="m-0 p-0">
                {/* <h5 className="pt-3">Account Balance</h5> */}
                <div className="row">
                  <div className="col-12 col-xl col-lg-3 col-md-6 mb-2">
                    <div className="dashboard-info-box btm-space-mob d-flex justify-content-between paypal-info align-items-center">
                      {/* <FontAwesomeIcon className="info-icon-main" icon={faUsd} /> */}
                      <Image preview={false} width={40} src={paypalIcon} />
                      <div className="">
                        <div class="total-info-hd my-1 text-left">{validate?.payload?.data?.paypal_email ? "Paypal" : "Please Connect To Paypal"}</div>
                        <div class="value-info-hd">
                          <Tooltip title={validate?.payload?.data?.paypal_email} overlayStyle={{ maxWidth: '500px' }}>
                            <div className="limit-line">
                              {validate?.payload?.data?.paypal_email}
                            </div>
                          </Tooltip>
                        </div>
                        {/* <div class="total-info-hd">Total Balance</div> */}
                        {validate?.payload?.data?.paypal_email !== "" ?
                          <Button className="default-btn  small-btn padt-4 w-initial m-0 btn btn-primary float-left"
                            onClick={DisconnectPaypal}
                          >
                            Disconnect Paypal
                          </Button>
                          :
                          <Button className="default-btn  small-btn padt-4 w-initial m-0 btn btn-primary"
                            onClick={ConnectPaypal}
                          >
                            Connect Paypal
                          </Button>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl col-lg-3 col-md-6 mb-2">
                    <div className="dashboard-info-box btm-space-mob paypal-info d-flex justify-content-between align-items-center">
                      <FontAwesomeIcon className="info-icon-main icon-xs position-relative m-0" icon={faUsd} />
                      <div>
                        <div class="value-info-hd">
                          {loading || paypalEarnings?.loading ? (
                            <Spin size="small" />
                          )
                            :
                            <div className="font-2r">
                              {LifetimeEarning ? numeral(LifetimeEarning).format("$0,0.0'") : "$0.00"}
                            </div>}
                        </div>
                        {/* <div class="total-info-hd">Total Balance</div> */}
                        <div class="total-info-hd">Lifetime Earning</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl col-lg-3 col-md-6 mb-2">
                    <div className="dashboard-info-box btm-space-mob paypal-info d-flex justify-content-between align-items-center">
                      <FontAwesomeIcon className="info-icon-main icon-xs position-relative m-0" icon={faUsd} />
                      <div>
                        <div class="value-info-hd">
                          {loading || paypalEarnings?.loading ? (
                            <Spin size="small" />
                          )
                            :
                            <div className="font-2r">
                              {totalEarning ? numeral(totalEarning).format("$0,0.0'") : "$0.00"}
                            </div>}
                        </div>
                        <div class="total-info-hd">Pending Balance</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl col-lg-3 col-md-6 mb-2">
                    <div className="dashboard-info-box btm-space-mob paypal-info d-flex justify-content-between align-items-center">
                      <FontAwesomeIcon className="info-icon-main icon-xs position-relative m-0" icon={faUsd} />
                      <div>
                        <div class="value-info-hd">
                          {loading || paypalEarnings?.loading ? (
                            <Spin size="small" />
                          )
                            : <div className="font-2r">
                              {totalWithdraw ? numeral(totalWithdraw).format("$0,0.0'") : "$0.00"}
                            </div>}
                        </div>
                        <div class="total-info-hd my-1">Available to Withdraw</div>
                        {
                          validate?.payload?.data?.paypal_email !== "" &&
                          <>
                            <Tooltip title={`${(isNaN(totalWithdraw) ||
                              totalWithdraw <= 0 || totalWithdraw <= minamount) ? " Minimum amount that can be withdrawn is $25." : ""}`}>
                              <span>
                                <Button className="default-btn  small-btn padt-4 w-initial m-0 btn btn-primary"
                                  disabled={
                                    isNaN(totalWithdraw) ||
                                    totalWithdraw <= 0 || totalWithdraw <= minamount
                                  }
                                  onClick={() => {
                                    setWithdrawModal(true);
                                    setInputValue(true);
                                    setIsChecked(false)
                                  }}
                                >
                                  Withdraw Amount
                                </Button>
                              </span>
                            </Tooltip>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="withdraw-request aff-payment">
          <div class="title-area">
            <h1 class="page-title">Withdraw Request</h1>
          </div>
          <hr />
          <div className="grid-listing-area flex-column tabs-overflow-scroll scrollbar-style flex-sm-row align-items-start align-items-sm-center withdraw-request ">
            <div className="grid-listing-left flex-grow-1 mb-2">
              <Tabs defaultActiveKey="all" onChange={handleTabChange} className="events-tabs-main">
                <TabPane tab="All Requests" key="All" />
                <TabPane tab="Pending" key="Pending" />
                <TabPane tab="In Process" key="Processing" />
                <TabPane tab="Successful" key="Approved" />
                <TabPane tab="Failed" key="Rejected" />
              </Tabs>
            </div>
          </div>
          <hr className="my-2 mb-4" />
          {tableloading ? (
            // <div className="antd-loading-ift">
            // 	<Spin size="large" />
            // </div>
            <div className="loading-wrap">
              <span className="spinclass loader-center position-relative">
                <Spin size="large" />
              </span>
            </div>
          ) : (
            <>
              {<DefaultTable />}
              <div className="purchase-data referral-data">
                {paypalReport?.payload?.data?.length ? (
                  <ReactPaginate
                    nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                    pageCount={Math.ceil(
                      paypalReport?.payload?.totalCount / limit
                    )}
                    forcePage={currentPage}
                    previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                    pageClassName="page-item d-none"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item d-none"
                    breakLinkClassName="page-link"
                    containerClassName={"pagination custom-paginate"}
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                ) : (
                  ""
                )}
              </div>
            </>
          )}

        </div>

      </div>


      <Modal
        // dialogClassName="code-activated-popup"
        // size="md"
        // scrollable
        // show={withdrawModal}
        // onHide={() => setWithdrawModal(false)}
        // centered
        className="modal-generic modal-500"
        centered
        visible={withdrawModal}
        // onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
        maskClosable={false}
      >
        {/* <Modal.Header closeButton></Modal.Header>
        <Modal.Body> */}
        <Form
          className="modal-form-style"
          form={form}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 24,
          }}
          autoComplete="off"
        >
          <h3>Notice</h3>
          <p>
            A 2% processing fees will be charged by PayPal. Maximum amount that can be withdrawn is ${maxamount}. Thank you for your understanding.
          </p>

          <Checkbox checked={isChecked} onChange={handleCheckboxChange}>I have read and understood the above.</Checkbox>
          <div className="d-flex w-100 pt-3">
            <InputNumber
              className="flex-grow-1"
              addonBefore="$"
              // min={1}
              value={inputValue}
              onChange={(value) => setInputValue(value)}
              size="large"
              onKeyDown={(event) => {
                if (inputValue === null && event.key === '0') {
                  event.preventDefault();
                }
              }}
              // formatter={(value) => `${value}`}
              // parser={(value) => value.replace(".", "")}
              placeholder={`Enter amount between $${minamount} and $${maxamount}`}
              type="number"
            />
            <Button
              onClick={handleSubmit}
              className="default-btn  medium-btn padt-4 w-initial m-0 btn btn-primary h-40 ml-2"
              htmlType="submit"
              disabled={inputValue < minamount || inputValue > maxamount || !isChecked}
            >
              Submit
            </Button>
          </div>
        </Form>
        {/* </Modal.Body> */}
      </Modal>
    </>
  );
};
