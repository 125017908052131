import {
  END_MEETING_REQUEST,
  END_MEETING_SUCCESS,
  END_MEETING_ERROR
} from "../../types/types";

export default function endMeeting(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case END_MEETING_REQUEST:
      return {
        loading: true,
      };
    case END_MEETING_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    case END_MEETING_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    default:
      return state;
  }
}
