import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  unstable_HistoryRouter as HistoryRouter,
} from "react-router-dom";
import Login from "../pages/login/login";
import Menus from "./menus";
import { createBrowserHistory } from "history";
import ForgotPassword from "../pages/forgot-password/forgotPassword";
import ResetPassword from "../pages/reset-password/resetPassword";
import PublicHome from "../pages/public/public-home";
import PublicAbout from "../pages/public/public-about";
import PublicPackage from "../pages/public/public-package";
import PublicPrivacyPolicy from "../pages/public/public-privacy-policy";
import PublicTermsUse from "../pages/public/public-terms-use";
import PublicContactUs from "../pages/public/public-contact-us";
import ScrollToTop from "../components/scrollTop/scrollTop";
import { useDispatch, useSelector } from "react-redux";
import { BASEURL } from "../config";
import axios from "axios";
import SkeletonComponent from "../components/SkeletenLoader";
import Agreement from "../pages/public/agreement";
import DirectAuth from "../pages/public/direct-auth";
import { validateUser } from "../redux/actions/login.action";

const history = createBrowserHistory();

const token = JSON.parse(localStorage.getItem("token"));
const Router = () => {
  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);
  const { validate } = useSelector((state) => { return state })
  const [load, setLoad] = useState(true)
  const [hit, setHit] = useState(false)
  const dispatch = useDispatch();


  axios.defaults.baseURL = BASEURL;
  axios.defaults.headers.common['Authorization'] = `Bearer ${validate?.payload?.data?.token}`;
  // axios.defaults.headers.common['public-ip'] = publicIP ? publicIP : "";
  axios.interceptors.request.use(request => {
    return request;
  }, error => {
    return Promise.reject(error);
  });

  useEffect(() => {
    if (token && !window.location.href.includes("auth")) {
      dispatch(validateUser(validate?.payload?.data?.token)).then((res) => {
        if (res.success) {
          setLoad(false)
        } else {
          setLoad(false)
        }
      })
    } else {
      setLoad(false)
    }
  }, [])



  function Redirect({ to }) {
    let navigate = useNavigate();
    useEffect(() => {
      navigate(to);
    });
    return null;
  }
  return (
    <>
      <HistoryRouter history={history}>
        {load ? <SkeletonComponent /> : (!validate?.payload?.success ? (
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Login setHit={setHit} />} />
              <Route path="/about" element={<PublicAbout />} />
              <Route path="/privacy-policy" element={<PublicPrivacyPolicy />} />
              <Route path="/terms-use" element={<PublicTermsUse />} />
              <Route path="/contact-us" element={<PublicContactUs />} />
              <Route path="/login" element={<Login />} />
              <Route path="/auth/:token" element={<DirectAuth />} />
              {/* <Route path="/register" element={<Register />} /> */}
              <Route
                path="/forgot-password"
                exact
                element={<ForgotPassword />}
              />
              <Route
                path="/password-reset/:id"
                exact
                element={<ResetPassword />}
              />
              <Route path="*" element={<Redirect to="/" />} />
            </Routes>
          </ScrollToTop>
        ) : (

          validate?.payload?.data?.agreement_status === "waiting" || validate?.payload?.data?.agreement_status === "rejected" ?
            <ScrollToTop>
              <Routes>
                <Route path="/agreement" element={<Agreement />} />
                <Route path="/" element={<Agreement />} />
                <Route path="/auth/:token" element={<DirectAuth />} />
                <Route path="*" element={<Redirect to="/" />} />
              </Routes>
            </ScrollToTop>
            :
            <Menus />
        ))
        }
      </HistoryRouter>
    </>
  );
};
export default Router;
