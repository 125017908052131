import {
    GET_CUSTOM_SHOP_REQUEST,
    GET_CUSTOM_SHOP_SUCCESS,
    GET_CUSTOM_SHOP_ERROR
} from "../../types/types";
export default function getCustomShopDetails(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_CUSTOM_SHOP_REQUEST:
            return {
                loading: true,
            };
        case GET_CUSTOM_SHOP_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: payload,
            };
        case GET_CUSTOM_SHOP_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };
        default:
            return state;
    }
}