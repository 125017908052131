// import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
// import { NavLink } from "react-router-dom";
// import { Collapse } from "antd";
// const { Panel } = Collapse;

export default () => {
  return (
    <>
      {/* <div className="footer-main">
        <ul className="footer-nav">
          <li>
            <a
              href="https://ormelive.com/faqs"
              target={"_blank"}
              rel="noreferrer"
            >
              {" "}
              Q&A
            </a>
          </li>
          <li>
            <a
              href="https://ormelive.com/terms-service"
              target={"_blank"}
              rel="noreferrer"
            >
              Terms and Conditions
            </a>
          </li>
          <li>
            <a
              href="https://ormelive.com/privacy-policy"
              target={"_blank"}
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </li>
        </ul>
      </div> */}
    </>
  );
};
