import { GET_REVIEW_DETAILS_REQUEST, GET_REVIEW_DETAILS_SUCCESS, GET_REVIEW_DETAILS_ERROR } from "../../types/types";
export default function detailsReview(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_REVIEW_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case GET_REVIEW_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case GET_REVIEW_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    default:
      return state;
  }
}