import { SOCIAL_MEDIA_FOLLOWERS } from "../../types/types";
export default function getSocialMediaFollowers(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case SOCIAL_MEDIA_FOLLOWERS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		default:
			return state;
	}
}