import {
  GET_METADATA_REQUEST,
  GET_METADATA_SUCCESS,
  GET_METADATA_ERROR,
} from "../../types/types";

export default function getMetaData(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_METADATA_REQUEST:
      return {
        loading: true,
      };
    case GET_METADATA_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    case GET_METADATA_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    default:
      return state;
  }
}
