import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { Spin, Alert } from "antd";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "../../css/login.scss";
import logo from "../../images/ormelogo.svg";
import { forgetPassword } from "../../redux/actions/forgotPassword.action";

export default function ForgotPassword() {
  const dispatch = useDispatch();

  const [spinner, setSpin] = useState(false);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const onFinish = (values) => {
    setSpin(true);
    dispatch(forgetPassword(values.email)).then((res) => {
      if (res.payload.success) {
        setSpin(false);
        setSuccess(true);
        setError("");
      } else {
        setSpin(false);
        setSuccess(false);
        setError(res.payload.data.message);
      }
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onValuesChange = () => {
    setError("");
  };

  return (
    <>
      <div className="login-main">
        <div className="login-left">
          <div className="login-left-content">
            <div className="intro-main">
              <h2>Empowering Agencies</h2>
              <p>
                Manage all your talent opportunities in one place. Invite influencers and revolutionize revenue streams.
              </p>
              {/* <h1>Introducing</h1> */}
              {/* <h2>AGENCY</h2> */}
              {/* <p>
                Boost your sales, create authentic and engaging relationships
                with your customers, in an innovative way!
              </p> */}
            </div>
          </div>
        </div>
        <div className="login-right">
          <div className="login-right-content form-styling">
            <div className="logo-login">
              <Link to="/"> <img src={logo} alt="logo" />
              </Link>
            </div>
            {!success ? <>
              <h2 className="login-hd mb-10">Forgot password?</h2>
              <p className="">We’ll send you a link to reset your password.</p>

              <Form
                name="basic"
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onValuesChange={onValuesChange}
                autoComplete="off"
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: 'Please enter valid email',
                    },
                    {
                      required: true,
                      message: 'Please enter your email',
                    },
                  ]}
                >
                  <Input size="large" placeholder="Email" />
                </Form.Item>

                {error && <div class="ant-form-item-explain-error">{error}</div>}

                <Form.Item>
                  <Spin spinning={spinner}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="default-btn login-btn reset-btn w-100"
                    >
                      Reset Password
                    </Button>
                  </Spin>
                </Form.Item>
              </Form></>
              : <Alert
                message="Email Sent"
                description="Forgot Password Successfully Please check your email"
                type="success"
                showIcon
                className="mb-3"
              />
            }

            <Link className="signin-link" to="/login">
              Sign In
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
