import { REFERRAL_STATS } from "../../types/types";
export default function referralStats(state = "", action) {
	const { type, payload } = action;
	switch (type) {
		case REFERRAL_STATS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		default:
			return state;
	}
}
