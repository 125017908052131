import {
    GET_INVITE_USER_REQUEST,
    GET_INVITE_USER_SUCCESS,
    GET_INVITE_USER_ERROR,
} from "../../types/types";
export default function getInvitedUsers(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_INVITE_USER_REQUEST:
            return {
                loading: true,
            };
        case GET_INVITE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: payload,
            };
        case GET_INVITE_USER_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };
        default:
            return state;
    }
}