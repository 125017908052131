import { GET_ORDER_DETAILS} from "../../types/types";
export default function detailsOrder(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ORDER_DETAILS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    default:
      return state;
  }
}