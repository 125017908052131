import {
  PUBLISH_EVENT_REQUEST,
  PUBLISH_EVENT_SUCCESS,
  PUBLISH_EVENT_ERROR,
} from '../../types/types';

export default function publishEvent(state = '', action) {
  const { type, payload } = action;
  switch (type) {
    case PUBLISH_EVENT_REQUEST:
      return {
        loading: true,
        success: true,
      };
    case PUBLISH_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: false,
        payload: payload,
      };

    case PUBLISH_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        payload: payload,
      };

    default:
      return state;
  }
}
