// ARAJ IP
// export const BASEURL = "http://172.16.1.85:9090/v1/"; 
//
// KASHIF IP ADDRESS
// export const BASEURL = 'http://172.16.1.157:9090/v1/'; //kashif
// export const BASEURL = 'http://172.16.2.95:9090/v1/'; //aman

/// LIVE URL  
export const BASEURL = "https://backend.ormelive.com/v1/"; //dev
// export const BASEURL = "https://backendlive.ormelive.com/v1/"; //live 

export const redirectURL = "https://agencydev.ormelive.com";
export const visitorURL = "https://dev.ormelive.com";
export const TEMPURL = "https://kbsly.com/v1/";
export const CUSTOMURL = "https://apiv2-dev.ormelive.com/v1/";


