import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  DatePicker,
  Spin,
  Empty,
  Checkbox,
  Select,
  Tag
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faShareNodes,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "../../css/sales.scss";
import moment from "moment";
import numeral from "numeral";
import { bannedUsers } from "../../redux/actions/banUserList";
import { useStepsForm } from "sunflower-antd";
import {
  createDiscount,
  deleteDiscount,
  discountActivation,
  getDiscount,
  updateDiscount,
} from "../../redux/actions/discount.action";
import Swal from "sweetalert2";
import { getInvitedTalent, inviteTalent } from "../../redux/actions/inviteTalent.action";
import { visitorURL } from "../../config";

const { RangePicker } = DatePicker;

export default () => {
  // const [form] = Form.useForm();
  const dateFormat = "MM-DD-YYYY";
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const min_date = moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD");
  const max_date = moment(new Date()).format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(min_date);
  const [endDate, setEndDate] = useState(max_date);
  const [currentPage, setCurrentPage] = useState(0);

  const [inviteModal, setInviteModal] = useState(false);
  const [formState, setFormState] = useState("add");
  const [saveLoading, setSaveLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState("");
  const [tempEmail, setTempEmail] = useState();
  const [emails, setEmails] = useState([]);
  const { Option } = Select;
  const limit = 10;

  // Handles changes to the emails, whether adding or removing
  const handleEmailChange = (newEmails) => {
    // Filter to ensure only valid emails are retained
    // const filteredEmails = newEmails.filter(email => email && isValidEmail(email));
    const filteredEmails = newEmails.filter(email => email && isValidEmail(email));
    setEmails(filteredEmails);
    form.setFieldsValue({
      name: filteredEmails
    })
  };

  // Validates email format
  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const { getInvitedTalents, validate } = useSelector((state) => {
    return state;
  });

  useEffect(() => {
    dispatch(getInvitedTalent(1, limit)).then((res) => {
      setLoading(false);
    });
  }, []);

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handlePageClick = (e) => {
    const page = e.selected;
    setCurrentPage(page);
    setLoading(true);
    dispatch(getInvitedTalent(page + 1, limit)).then((res) => {
      setLoading(false);
    });
  };

  const handleCancel = () => {
    setEmails([])
    setInviteModal(false);
    form.resetFields();
    // setFormState("add")
  };

  const handleOk = () => {
    // setInviteModal(false)
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const selectStatus = (value) => {
    setFilterStatus(value);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(getInvitedTalent(1, limit, moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"), tempEmail)).then((res) => {
      setLoading(false);
      setCurrentPage(0);
    });
  };
  const handleReset = (res) => {
    setLoading(true);
    // setInfluencerId();
    setFilterStatus("");
    // childRef.current?.clear();
    // setLoadd(true)
    const min_date = moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD");
    const max_date = moment().endOf("year").format("YYYY-MM-DD");
    setStartDate(min_date);
    setEndDate(max_date);
    setTempEmail()
    dispatch(getInvitedTalent(1, limit, min_date, max_date)).then((res) => {
      setLoading(false);
      // setInfluencer("");
      setCurrentPage(0);
    });
  };
  const {
    form,
    current,
    gotoStep,
    stepsProps,
    formProps,
    submit,
    formLoading,
  } = useStepsForm({
    async submit(values) {

      let data = {
        email: emails,
      };
      setSaveLoading(true);
      dispatch(inviteTalent(data)).then((res) => {
        console.log(res, "res");
        if (res.type === "INVITE_TALENT_SUCCESS") {
          notification.success({
            message: res?.payload?.message,
            className: "toast-success",
          });
          setInviteModal(false);
          form.resetFields();
          setSaveLoading(false);
          setCurrentPage(0);
          // dispatch(validateUser(token));
          // }
          setLoading(true);
          dispatch(getInvitedTalent(1, limit)).then((res) => {
            if (res.type == "GET_INVITE_TALENT_SUCCESS") {
              setLoading(false);
              // form.setFieldsValue({
              //   shopUrl: res?.payload?.message?.shopify?.shop_name,
              //   apiKey: res?.payload?.message?.shopify?.api_key,
              //   token: res?.payload?.message?.shopify?.password,
              //   storefrontKey:
              //     res?.payload?.message?.shopify?.storefront_access_token,
              // });
              // setFormState("edit");
              // handleClose();
            } else {
              if (res.type == "GET_INVITE_TALENT_ERROR") {
                setLoading(false);
                setSaveLoading(false);
              }
            }
          });
        } else {
          console.log(res);
          notification.error({
            message: res?.payload?.data?.message,
            className: "toast-error",
          });
          setSaveLoading(false);
        }
      });
    },
  });
  function startDateLimit(current) {
    return current && current < moment().startOf("day");
  }

  const removeDiscount = (id) => {
    Swal.fire({
      title: `Are You Sure You Want To delete this discount?`,
      icon: "warning",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: "#000080",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        // setSpin(true);
        dispatch(deleteDiscount(id)).then((res) => {
          console.log(res, "resdelete");
          if (res?.type === "DELETE_DISCOUNT_ERROR") {
            notification.error({
              message: res?.payload?.data?.message,
              className: "toast-error",
            });
          } else {
            notification.success({
              message: res?.message,
              className: "toast-success",
            });
            setLoading(true);
            dispatch(getDiscount(1, limit)).then((res) => {
              setLoading(false);
            });
          }
          // setSpin(false);
        });
      }
    });
  };

  const editDiscount = (item) => {
    setInviteModal(true);
    form.setFieldsValue({
      name: item?.name,
      discount: item?.discount,
      discounted_product_marked: item?.discounted_product_marked,
      discount_duration: [moment(item?.start_date, 'YYYY-MM-DD'), moment(item?.end_date, 'YYYY-MM-DD')],
      _id: item?._id
    });
    setFormState("edit");
  };

  const discountAction = (item) => {
    console.log(item, "item");
    Swal.fire({
      title: `Are You Sure You Want To ${item.active ? "DeActivate" : "Activate"} this discount?`,
      icon: "warning",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: "#000080",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        // setSpin(true);
        let data = {
          id: item._id,
          active: !item.active
        }
        dispatch(discountActivation(data)).then((res) => {
          console.log(res, "discountActivation");
          if (res?.type === "ACTIVATION_DISCOUNT_ERROR") {
            notification.error({
              message: res?.payload?.data?.message,
              className: "toast-error",
            });
          } else {
            notification.success({
              message: res?.message,
              className: "toast-success",
            });
            setLoading(true);
            dispatch(getDiscount(1, limit)).then((res) => {
              setLoading(false);
            });
          }
          // setSpin(false);
        });
      }
    });
  };

  function DefaultTable() {
    // let data = shopifyCustomerorder?.payload?.message?.data;
    if (getInvitedTalents?.payload?.data) {
      return (
        <>
          <div className="table-responsive scrollbar-style">
            <table className="transactions-box table">
              <thead className="table_heading">
                <tr>
                  <th className="td-width-60">S.#</th>
                  <th className="td-width-160">Receiver Email</th>
                  {/* <th className="td-width-160">Sender Email</th> */}
                  <th className="td-width-160">Sent Date</th>
                  {/* <th className="td-width-200 text-center">Status</th> */}
                </tr>
              </thead>
              <tbody>
                {getInvitedTalents?.payload?.data?.length === 0 ? (
                  <tr>
                    <td colspan="12">
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </td>
                  </tr>
                ) : (
                  getInvitedTalents?.payload?.data?.map((item, i) => {
                    return (
                      <tr>
                        <td>{i + 1 + currentPage * limit}</td>
                        <td>{item?.to_email}</td>
                        {/* <td>{item?.sender_email}</td> */}
                        <td className="pt-2 pb-2">{moment(item?.created_at).format("YYYY-MM-DD")}</td>
                        {/* <td className="text-center pt-2 pb-2">

                          <Tag color="orange">Pending</Tag>
                          
                        </td> */}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </>
      );
    }
  }

  const formInvite = [
    <div className="form-styling ">
      <Form.Item
        name="name"
        labelCol={{ span: 24 }}
        label="Enter Emails"
        // initialValue={formState === "add" ? null : form.getFieldValue().name}
        rules={[
          {
            required: true,
            message: "Please Enter Email",
          },
        ]}
      >
        <Select
          mode="tags"
          placeholder="Enter Email"
          onChange={handleEmailChange}
          value={emails}
          tokenSeparators={[', ', ',']} // Defines characters that trigger a separation into tags
          allowClear
          size="large"
          dropdownStyle={{ display: 'none' }} // This prevents the dropdown from being visible
          dropdownRender={() => null} // This prevents the dropdown from rendering
        >
          {emails?.map(email => (
            <Option key={email} value={email}>{email}</Option>
          ))}
        </Select>
      </Form.Item>
      <div className="d-flex justify-content-end">
        <Button
          className="default-btn d-inline-flex align-items-center justify-content-center"
          onClick={submit}
          // onClick={() => console.log('Emails:', emailList.split(',').map(email => email.trim()))}
          disabled={saveLoading}
          loading={saveLoading}
        >
          <span className="nav-text">
            Invite
          </span>
        </Button>
        <Button
          className="default-btn outline d-inline-flex align-items-center justify-content-center"
          key="back"
          onClick={handleCancel}
        >
          Exit
        </Button>
      </div>
    </div>,
  ];

  const copyUrl = (url) => {
    navigator.clipboard.writeText(url);
    notification.success({
      message: "Agency Share Link Copied!",
      className: "toast-success",
    });
  };

  return (
    <>
      <div className="top-btn-area mb-10 d-flex justify-content-between">
        <button
          onClick={() => setInviteModal(true)}
          className="default-btn d-flex align-items-center justify-content-center m-0 create-event-btn"
        >
          <span className="nav-text ">Invite Talent</span>
        </button>
        <button
          onClick={() => copyUrl(`${visitorURL}?agency_by=${validate?.payload?.data?._id}`)}
          className="default-btn d-flex align-items-center justify-content-center m-0 create-event-btn"
        >
          <FontAwesomeIcon icon={faShareNodes} />
          <span>Share</span>
        </button>
      </div>
      <div className="sales-page-main">
        <div className="grid-listing-area flex-column  flex-sm-column align-items-sm-start flex-md-column align-items-md-start flex-lg-column align-items-lg-start flex-xl-row align-items-start position-relative">
          <div className="grid-listing-left flex-grow-1">
            <div class="title-area mb-0 pb-1">
              <h1>Logs</h1>
            </div>
          </div>
        </div>

        <div className="brand_container_main aff-payment">
          <Form form={form}>
            <div className="row w-20-desktop-main">

              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Date Range</p>
                <RangePicker
                  size="large"
                  className="w-100"
                  key={4}
                  value={
                    startDate && endDate
                      ? [moment(startDate), moment(endDate)]
                      : []
                  }
                  allowClear={false}
                  ranges={{
                    "All Time": [
                      moment().subtract(1, "year").startOf("year"),
                      moment(),
                    ],
                    "YTD": [moment().startOf("year"), moment()],
                    "Last 30 Days": [moment().subtract(30, "days"), moment()],
                    "Last 7 Days": [moment().subtract(7, "days"), moment()],
                    "Today": [moment(), moment()],
                  }}
                  format={dateFormat}
                  onChange={dateRangePickerChanger}
                />
              </div>

              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Email</p>
                <Input
                  className="w-100"
                  placeholder="Enter Email"
                  size="large"
                  type="text"
                  value={tempEmail}
                  onChange={(e) => setTempEmail(e?.target?.value)}
                />
              </div>
              {/* <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Status</p>
                <Select
                  showSearch
                  name="sort"
                  size="large"
                  placeholder="Select Status"
                  className="w-100 select-style dark-field"
                  optionFilterProp="children"
                  value={filterStatus}
                  defaultValue=""
                  onChange={(value) => selectStatus(value)}
                  filterOption={filterOption}
                  options={[
                    { value: '', label: 'All' },
                    { value: 'active', label: 'Activated' },
                    { value: 'deactive', label: 'De-Activated' },
                  ]}
                />
              </div> */}





              <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-15">
                <Button
                  onClick={handleSubmit}
                  className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                  type="primary"
                  htmlType="submit"
                >
                  Search
                </Button>
                <Button
                  onClick={handleReset}
                  className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
                  type="primary"
                  htmlType="submit"
                >
                  Reset
                </Button>
              </div>
            </div>



          </Form>
          <hr className="separator-line mt-0" />
          <div className="purchase-data referral-data">
            {loading ? (
              <div className="loading-wrap">
                <span className="spinclass loader-center position-relative">
                  <Spin size="large" />
                </span>
              </div>
            ) : (
              <>
                {<DefaultTable />}
                <div className="purchase-data referral-data">
                  {getInvitedTalents?.payload?.data?.length ? (
                    <ReactPaginate
                      nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                      pageCount={Math.ceil(
                        getInvitedTalents?.payload?.total / limit
                      )}
                      forcePage={currentPage}
                      previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                      pageClassName="page-item d-none"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item d-none"
                      breakLinkClassName="page-link"
                      containerClassName={"pagination custom-paginate"}
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <Modal
        className="modal-generic modal-600"
        centered
        open={inviteModal}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
        maskClosable={false}
        keyboard={false}
      >
        <h2 className="modal-hd1">Invite Talent</h2>
        {!loading ? (
          <Form {...formProps}>{formInvite}</Form>
        ) : (
          <span className="spinclass loader-center position-relative">
            <Spin size="large" />
          </span>
        )}
      </Modal>
    </>
  );
};
