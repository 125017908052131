import { GET_INVENTORY_DISABLED } from "../../types/types";


export default function getInventoryDisabled(state = [], action) {
  switch (action.type) {
    case GET_INVENTORY_DISABLED:
      return action.payload
    default:
      return state;
  }
}
