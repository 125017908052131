import { GET_INSTA_REEL_POST } from "../../types/types";

const initialState = []

export default function getInstaReelData(state = initialState, action) {
  switch (action.type) {
    case GET_INSTA_REEL_POST:
      return action.payload
    default:
      return state;
  }
}
