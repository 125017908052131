import {
  UPCOMING_EVENT_REQUEST,
  UPCOMING_EVENT_SUCCESS,
  UPCOMING_EVENT_ERROR,
} from "../../types/types";
export default function upcomingEvent(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case UPCOMING_EVENT_REQUEST:
      return {
        loading: true,
      };
    case UPCOMING_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case UPCOMING_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    default:
      return state;
  }
}
