import { GET_REEL_DELETE_POST } from "../../types/types";

const initialState = []

export default function getReelDelete(state = initialState, action) {
  switch (action.type) {
    case GET_REEL_DELETE_POST:
      return action.payload
    default:
      return state;
  }
}
