import {
  STRIPE_PAYOUT,
} from "../../types/types";

export default function stripePayout(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case STRIPE_PAYOUT:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    default:
      return state;
  }
}
