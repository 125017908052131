import {
    POST_STATE_REQUEST,
    POST_STATE_SUCCESS,
    POST_STATE_ERROR,
  } from "../../types/types";
  
  export default function state(state = "", action) {
    const { type, payload } = action;
    switch (type) {
      case POST_STATE_REQUEST:
        return {
          loading: true,
          success: true,
        };
      case POST_STATE_SUCCESS:
        return {
          ...state,
          loading: false,
          success: false,
          payload: payload,
        };
  
      case POST_STATE_ERROR:
        return {
          ...state,
          loading: false,
          success: false,
          payload: payload,
        };
  
      default:
        return state;
    }
  }