import {
  POST_MAGENTO_REQUEST,
  POST_MAGENTO_SUCCESS,
  POST_MAGENTO_ERROR
  } from "../../types/types";
  
  export default function createMagento(state = "", action) {
    const { type, payload } = action;
    switch (type) {
      case POST_MAGENTO_REQUEST:
        return {
          loading: true,
          success: true,
        };
      case POST_MAGENTO_SUCCESS:
        return {
          ...state,
          loading: false,
          success: false,
          payload: payload,
        };
  
      case POST_MAGENTO_ERROR:
        return {
          ...state,
          loading: false,
          success: false,
          payload: payload,
        };
  
      default:
        return state;
    }
  }
  