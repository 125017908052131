import axios from "axios";
import React from "react";
import AsyncSelect from "react-select/async";
// import { toast } from "react-toastify";
import { notification } from "antd";
import { BASEURL } from "../../../config";
import "../../../css/asyncProduct.scss";

let arrHost = [];
let clears = false;
let host = []
class AsyncCustomer extends React.Component {
    state = {
        reset: this.props.reset,
        host2: this.props.host2,
        host3: this.props.host3,
        host4: this.props.host4,
        allHost: "",
        form: this.props.form,
        flag: this.props.flag,
        status: false
    };


    componentDidUpdate() {

        if (this.props.reset === true && this.props.form === false) {
            clears = true
            host = []
        }
        if (this.props.form === true) {
            clears = false
            if (host.length > 0) {
            }
            else { host = [] }
        }
    }

    loadOptions = async (input, callback) => {
        await this.smartSearchFilter(input);

        const result = arrHost.filter((item) => {
            if (item?.label == this.props.host?.[0]?.label || item?.label == this.props.host2?.[0]?.label || item?.label == this.props.host3?.[0]?.label || item?.label == this.props.host4?.[0]?.label) {
            } else {
                return {
                    value: `${item.value}`,
                    label: `${item.label}`,
                };
            }
        });

        // const result = arrHost.map((item) => {
        //   if (item?.label == this.props.host?.[0]?.label || item?.label == this.props.host2?.[0]?.label || item?.label == this.props.host3?.[0]?.label || item?.label == this.props.host4?.[0]?.label) {
        //   } else {
        //     return {
        //       value: `${item.value}`,
        //       label: `${item.label}`,
        //     };
        //   }
        // });
        callback(result);
    };

    //   componentDidMount(){
    //   if(this.state.form == "edit"){
    //      this.setState({status:true})
    //   }
    // }

    smartSearchFilter = async (value) => {

        if (value.length > 0 && value.trim()) {
            await axios
                .post(
                    `brand/reports/getCustomers`,
                    {
                        name: value.trim(),
                    },
                )
                .then((response) => {
                    const loadHost = [];
                    const host = response.data.data;
                    if (host.length === 0) {
                        // notification.error({
                        //   message: "No Host Found",
                        //   className: "toast-error",
                        // });
                    }
                    this.setState({ allHost: host });
                    host.map((item) => {
                        return loadHost.push({
                            value: item?._id,
                            label: item?.first_name + " " + item?.last_name,
                        });
                    });
                    arrHost = loadHost;
                })
                .catch(function (error) {
                    // console.log(error);
                });
        }
    };

    handleMultiSelect = (e) => {
        if (this.props.reset === true) {
            clears = false
        }
        let data = [e];
        if (data.length <= 1) {
            this.props.getHostPrimary(data);
            host = data
            //   this.setState({
            //     host: data,
            //   });
            if (this.props.form === "edit") {
                this.props.flag(true);
            }
        } else {
            let lastRemoved = e.slice(0, -1);
            this.props.getHostPrimary(lastRemoved);
            host = lastRemoved
            this.setState({
                host: lastRemoved,
            });
            notification.error({
                message: "1 Referral are allowed",
                className: "toast-error",
            });
        }
    };


    handleOnPaste = async (e) => {
        const getData = e.clipboardData.getData("text");
        await this.smartSearchFilter(getData);
    };
    // formatOptionLabel = ({ label, title, price }) => (
    //   <div style={{ display: "flex" }}>
    //     <div>{label}</div>&nbsp;-&nbsp;
    //     <div style={{ marginLeft: "10px" }}>{title}</div>&nbsp;-&nbsp;
    //     <div style={{ marginLeft: "10px" }}>${price}</div>
    //   </div>
    // );

    render() {

        return (
            <React.Fragment>
                <div onPaste={(e) => this.handleOnPaste(e)}>
                    <AsyncSelect
                        isMulti={false}
                        cacheOptions
                        isClearable={true}
                        required
                        isDisabled={this.state.status}
                        defaultOptions
                        loadOptions={this.loadOptions}
                        placeholder="Enter Referral Name"
                        name={"host"}
                        value={clears === true ? [] : host}
                        onChange={(e, options) => {
                            this.handleMultiSelect(e, options);
                        }}
                        noOptionsMessage={({ inputValue }) => !inputValue ? "Referred By" : "No Referral Found"}
                    // formatOptionLabel={this.formatOptionLabel}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default AsyncCustomer;



