import {
  CONNECT_PAYPAL,
  DISCONNECT_PAYPAL,
  PAYPAL_EARNING_SUMMARY_REQUEST, PAYPAL_EARNING_SUMMARY_SUCCESS, PAYPAL_EARNING_SUMMARY_ERROR,
  GET_PAYPAL_LIMIT_REQUEST, GET_PAYPAL_LIMIT_SUCCESS, GET_PAYPAL_LIMIT_ERROR,
  PAYPAL_TRANSACTION_REPORT_REQUEST, PAYPAL_TRANSACTION_REPORT_SUCCESS, PAYPAL_TRANSACTION_REPORT_ERROR,
  PAYPAL_WITHDRAW_REQUEST, PAYPAL_WITHDRAW_SUCCESS, PAYPAL_WITHDRAW_ERROR

} from '../types/types';
import { BASEURL } from '../../config';
import axios from 'axios';


export const connectPaypal = (code) => (dispatch) => {
  return axios({
    method: 'POST',
    url: `/agency/withdraw/paypalRequest`,
    data: { code }
  })
    .then((response) => {
      dispatch({
        type: CONNECT_PAYPAL,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};


export const disconnectPaypal = () => (dispatch) => {
  return axios({
    method: 'POST',
    url: `/agency/withdraw/disconnect`,
  })
    .then((response) => {
      dispatch({
        type: DISCONNECT_PAYPAL,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};


// export const paypalEarningSummary = (data) => (dispatch) => {
//   return axios({
//     method: 'POST',
//     url: `/agency/reports/getearningsummary`,
//     data
//   })
//     .then((response) => {
//       dispatch({
//         type: PAYPAL_EARNING_SUMMARY,
//         payload: response.data,
//       });
//       return response.data;
//     })
//     .catch((error) => {
//       return error;
//     });
// };

export const paypalEarningSummary = (data) => (dispatch) => {
  dispatch({ type: PAYPAL_EARNING_SUMMARY_REQUEST });
  return axios
    .post("/agency/reports/getearningsummary",
      data
    )
    .then((res) => {
      return dispatch({
        type: PAYPAL_EARNING_SUMMARY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      return dispatch({
        type: PAYPAL_EARNING_SUMMARY_ERROR,
        payload: err.response,
      });
    });
};

export const getPaypalLimit = () => (dispatch) => {
  dispatch({ type: GET_PAYPAL_LIMIT_REQUEST });
  return axios
    .get("/agency/withdraw/getLimit",
      // data
    )
    .then((res) => {
      return dispatch({
        type: GET_PAYPAL_LIMIT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      return dispatch({
        type: GET_PAYPAL_LIMIT_ERROR,
        payload: err.response,
      });
    });
};

export const paypalTransactionReport = (page, limit, data) => (dispatch) => {
  dispatch({ type: PAYPAL_TRANSACTION_REPORT_REQUEST });
  return axios
    .post(`/agency/withdraw/getAll?limit=${limit}&page=${page}`,
      data
    )
    .then((res) => {
      return dispatch({
        type: PAYPAL_TRANSACTION_REPORT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      return dispatch({
        type: PAYPAL_TRANSACTION_REPORT_ERROR,
        payload: err.response,
      });
    });
};


export const paypalWithdraw = (data) => (dispatch) => {
  dispatch({ type: PAYPAL_WITHDRAW_REQUEST });
  return axios
    .post("/agency/withdraw/request",
      data
    )
    .then((res) => {
      return dispatch({
        type: PAYPAL_WITHDRAW_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      return dispatch({
        type: PAYPAL_WITHDRAW_ERROR,
        payload: err.response,
      });
    });
};