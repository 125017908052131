import { NEW_INFLUENCER } from "../../types/types";

const initialState = []

export default function getReviewControlData(state = initialState, action) {
  switch (action.type) {
    case NEW_INFLUENCER:
      return action.payload
    default:
      return state;
  }
}
