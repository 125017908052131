import {   GET_SHOW_RECORD_EVENTS_REQUEST,	GET_SHOW_RECORD_EVENTS_SUCCESS, GET_SHOW_RECORD_EVENTS_ERROR } from "../../types/types";
export default function getShowRecordEvents(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_SHOW_RECORD_EVENTS_REQUEST:
			return {
				loading: true,
			};
		case GET_SHOW_RECORD_EVENTS_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case GET_SHOW_RECORD_EVENTS_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}