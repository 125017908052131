import axios from 'axios';
import { BASEURL } from '../../config';

export const configSubs = () => async (dispatch) => {
    const res = await axios.post(`stripe/subscription/config`, {},
    )
    return res.data;
};

export const makePayment = (data) => async (dispatch) => {
    const res = await axios.post(`${BASEURL}/stripe/subscription/checkout`, data);
    return res.data.message;
};

export const updateSubscription = (data) => async (dispatch) => {
    const res = await axios.post(`subscribe/upgrade`, { data });
    return res.data;
};

export const subscribeServices = (val, priceId, module, interval, package_id) => async (dispatch) => {
    const res = await axios.post(`subscribe/addon`, {
        package_id: package_id,
        recurring_payment_type: interval,
        addon: module,
        quantity: val,
        price_id: priceId
    }
    );
    return res.data;
};