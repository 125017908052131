import {
    GET_SALESFORCE_DETAIL_REQUEST,
    GET_SALESFORCE_DETAIL_SUCCESS,
    GET_SALESFORCE_DETAIL_ERROR,
} from "../../types/types";
export default function getSalesForceDetails(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_SALESFORCE_DETAIL_REQUEST:
            return {
                loading: true,
            };
        case GET_SALESFORCE_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: payload,
            };
        case GET_SALESFORCE_DETAIL_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };
        default:
            return state;
    }
}