import {
    GET_INSTA_DATA, RESET_INSTA_STATE, UPDATE_INSTA_STATE
} from "../../types/types";


export default function state(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case RESET_INSTA_STATE:
            return state = [];
        case UPDATE_INSTA_STATE:
            return state = payload;

        case GET_INSTA_DATA:
            {
                return [...state, ...payload?.message]
            }
        default:
            return state;
    }
}
