import { LOCAL_CUSTOMER_ORDER_REQUEST, LOCAL_CUSTOMER_ORDER_SUCCESS, LOCAL_CUSTOMER_ORDER_ERROR } from "../../types/types";
export default function orderReview(state = "", action) {
	const { type, payload } = action;
	switch (type) {
		case LOCAL_CUSTOMER_ORDER_REQUEST:
			return {
				loading: true,
			};
			case LOCAL_CUSTOMER_ORDER_SUCCESS:
				return {
					...state,
					loading: false,
					payload: payload,
				};
		case LOCAL_CUSTOMER_ORDER_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		default:
			return state;
	}
}
