import {
	POST_CUSTOMER_REGISTER_REQUEST,
	POST_CUSTOMER_REGISTER_SUCCESS,
	POST_CUSTOMER_REGISTER_ERROR
} from "../../types/types";

export default function customerRegister(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case POST_CUSTOMER_REGISTER_REQUEST:
			return {
				loading: true,
			};
		case POST_CUSTOMER_REGISTER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case POST_CUSTOMER_REGISTER_ERROR:
			return {
				...state,
				loading: false,
				payload: payload ? payload : [],
			};

		default:
			return state;
	}
}
