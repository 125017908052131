import {
  POST_EVENT_REQUEST,
  POST_EVENT_SUCCESS,
  POST_EVENT_ERROR,
} from "../../types/types";

export default function creatEvent(state = "", action) {
  const { type, payload } = action;
  switch (type) {
    case POST_EVENT_REQUEST:
      return {
        loading: true,
        success: true,
      };
    case POST_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: false,
        payload: payload,
      };

    case POST_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        payload: payload,
      };

    default:
      return state;
  }
}
