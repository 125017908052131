import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, InputNumber, Modal, notification, Spin } from "antd";
import { Button } from "react-bootstrap";
import { Button as Buttond } from "antd";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  getAffiliateContractDetail,
  getAffiliateCards,
  makePayment,
  setPaymentDefault,
  deletePaymentMethod,
  editPaymentMethod,
} from "../../redux/actions/kbSetup";
import { getUserInfo } from "../../redux/actions/userInfo.action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
// import moment from "moment";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import {
  Viewer,
  Worker,
  ScrollMode,
  SpecialZoomLevel,
} from "@react-pdf-viewer/core";
import { getAgreementPDFLink } from "../../redux/actions/agreement.action";

export default () => {
  const { confirm } = Modal;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [methodLoad, setMethodLoad] = useState(false);
  const [data, setData] = useState(true);
  const [cardLoading, setCardLoading] = useState(true);
  const [defaultPaymentID, setDefaultPaymentID] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [expMonth, setExpMonth] = useState("");
  const [expYear, setExpYear] = useState("");
  const [methodID, setMethodID] = useState("");
  const [agreementData, setAgreementData] = useState("");
  const [aggModal, setAggModal] = useState(false);

  const [loader, setLoader] = useState(false);
  // const [pdfURL, setPdfUrl] = useState();

  const { affiliateCards, affiliatePayment, userInfo, validate } = useSelector(
    (state) => {
      return state;
    }
  );

  useEffect(() => {
    // dispatch(getAffiliateCards()).then(() => {
    //   setCardLoading(false);
    // }).catch((err) => {
    //   setCardLoading(false);
    // });
    // dispatch(makePayment()).then((res) => {
    // }).catch((err) => {
    // });;
    // dispatch(getUserInfo());
    // console.log(validate);
  }, []);

  useEffect(() => {
    // setLoading(true);
    // dispatch(getAffiliateContractDetail()).then((res) => {
    //   setLoading(false);
    //   setData(res?.payload?.message);
    // });
  }, []);

  useEffect(() => {
    // dispatch(getAgreementPDFLink()).then((res) => {
    //   // setPdfUrl(res?.data?.ducument_url);
    //   setAgreementData(res?.data);
    //   setLoader(false);
    // });
  }, []);

  const paymentMethod = () => {
    if (affiliatePayment?.success == true) {
      let data = affiliatePayment?.message;
      window.open(data, "_self");
    } else {
    }
  };

  const setMethodDefault = () => {
    setMethodLoad(true);
    dispatch(setPaymentDefault(defaultPaymentID)).then((res) => {
      if (res?.success) {
        setMethodLoad(false);
        notification.success({
          message: "Selected Payment Method Has Been Set To Default",
          className: "toast-success",
        });
        console.log("res", res);
        setDefaultPaymentID("");
      } else {
        console.log("error", res);
        setMethodLoad(false);
      }
    });
  };

  const deleteMethod = (id) => {
    confirm({
      wrapClassName: "modal-generic cancel-event-modal min-modal-600",
      // title: 'Warning',
      centered: true,
      closable: true,
      icon: <ExclamationCircleOutlined />,
      closeIcon: <FontAwesomeIcon icon={faXmark} />,
      content: "Are you sure you want to delete the payment method ?",
      okText: " Yes, Delete",
      okType: "success",
      cancelText: " No, I don't want to Delete",
      onOk() {
        dispatch(deletePaymentMethod(id)).then((res) => {
          if (res?.success) {
            notification.success({
              message: "Payment Method Has Been Deleted",
              className: "toast-success",
            });
            dispatch(getAffiliateCards())
              .then(() => {
                setCardLoading(false);
              })
              .catch((err) => {
                setCardLoading(false);
              });
            console.log("res", res);
          } else {
            console.log("error", res);
          }
        });
      },
      onCancel() {
        console.log("cancel Delete");
      },
    });
  };

  const editMethodDates = () => {
    setLoad(true);
    dispatch(editPaymentMethod(methodID, expMonth, expYear))
      .then((res) => {
        if (res.success) {
          notification.success({
            message: "Expiry Date Has Been Updated",
            className: "toast-success",
          });
          setShowModal(false);
          setLoad(false);
          setCardLoading(true);
          dispatch(getAffiliateCards())
            .then(() => {
              setCardLoading(false);
            })
            .catch((err) => {
              setCardLoading(false);
            });
        } else {
          setLoad(false);
        }
      })
      .catch((err) => {
        setLoad(false);
        notification.error({
          message: "Invalid Expiry Year",
          className: "toast-error",
        });
      });
  };

  function dataCardDetail() {
    let data = affiliateCards?.message?.data;
    if (data) {
      return (
        <>
          <div className="amount-box">
            {data.map((item, i) => {
              return (
                <>
                  <div className="deposit_card">
                    <input
                      type="radio"
                      name="card"
                      id={item.id}
                      class="infchecked"
                      value={item.id}
                      defaultChecked={i === 0 ? true : false}
                      onChange={() => {
                        setDefaultPaymentID(item?.id);
                      }}
                    />
                    <label for={item.id}>
                      <div className="pull-left">
                        <span className="card-name">{item.card.brand}</span>{" "}
                        ending in {item.card.last4}
                      </div>
                      <div className="text-right">
                        <span className="card-expired">
                          {" "}
                          Expired: {item.card.exp_month}/{item.card.exp_year}
                        </span>
                        {/* <span className="fa fa-pencil edit-icon ml-2"></span>
                          <span className="fa fa-trash ml-2"></span> */}
                      </div>
                    </label>
                    <Buttond
                      className="default-btn red-btn payment-btns ml-3 width-auto d-flex align-items-center justify-content-center"
                      type="primary"
                      size="small"
                      key="cancel"
                      onClick={() => deleteMethod(item?.id)}
                      icon={
                        <FontAwesomeIcon
                          size="sm"
                          className="event-icon"
                          icon={faTrash}
                        />
                      }
                    ></Buttond>
                    <Buttond
                      className="default-btn payment-btns width-auto d-flex align-items-center justify-content-center"
                      type="primary"
                      size="small"
                      key="cancel"
                      onClick={() => {
                        setShowModal(true);
                        setMethodID(item?.id);
                        setExpMonth(item?.card?.exp_month);
                        setExpYear(item?.card?.exp_year);
                      }}
                      // disabled
                      icon={
                        <FontAwesomeIcon
                          size="sm"
                          className="event-icon"
                          icon={faPen}
                        />
                      }
                    ></Buttond>
                  </div>
                </>
              );
            })}
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="amount-box pt-0">
              <Button
                className="default-btn ml-0 fw-normal"
                onClick={() => paymentMethod()}
              >
                Add Card
              </Button>
            </div>
            <div className="amount-box pt-0">
              <Buttond
                loading={methodLoad}
                className="default-btn ml-0 fw-normal"
                onClick={() => setMethodDefault()}
                disabled={defaultPaymentID ? false : true}
              >
                Save Method
              </Buttond>
            </div>
          </div>
          <Modal
            maskClosable={false}
            className="modal-generic modal-500"
            centered
            open={showModal}
            onCancel={() => setShowModal(false)}
            closeIcon={<FontAwesomeIcon icon={faXmark} />}
            footer={[
              <Buttond
                key="submit"
                type="primary"
                className="ml-3 default-btn"
                loading={load}
                disabled={!expMonth || !expYear}
                onClick={() => {
                  editMethodDates();
                }}
              >
                Update
              </Buttond>,
              <Button
                type="primary"
                className="default-btn outline"
                onClick={() => {
                  setShowModal(false);
                  setExpMonth("");
                  setExpYear("");
                }}
              >
                Cancel
              </Button>,
            ]}
          >
            <h2 className="modal-hd1">Update Expiry Dates</h2>
            <div className="row form-style-new">
              <div className="col-12 mb-15">
                <h6>Expiry Month</h6>
                <InputNumber
                  className="w-100 select-modal"
                  size="large"
                  value={expMonth}
                  parser={(value) => {
                    return value.substring(0, 2);
                  }}
                  min={1}
                  max={31}
                  onChange={(e) => {
                    {
                      setExpMonth(e);
                    }
                  }}
                />
              </div>
              <div className="col-12 mb-15">
                <h6>Expiry Year</h6>
                <InputNumber
                  className="w-100 select-modal"
                  size="large"
                  value={expYear}
                  parser={(value) => {
                    return value.substring(0, 4);
                  }}
                  min={1}
                  // max={100}
                  onChange={(e) => {
                    {
                      setExpYear(e);
                    }
                  }}
                />
              </div>
            </div>
            {/* <div className="my-4 d-flex justify-content-center align-items-center">
              <div>
                <h6>Expiry Month</h6>
                <InputNumber
                  className="w-50 select-modal"
                  size="large"
                  value={expMonth}
                  parser={(value) => {
                    return value.substring(0, 2)
                  }}
                  min={1}
                  max={31}
                  onChange={(e) => {
                    {
                      setExpMonth(e)
                    }
                  }}
                />
              </div>

              <div>
                <h6>Expiry Year</h6>
                <InputNumber
                  className="w-50 select-modal"
                  size="large"
                  value={expYear}
                  parser={(value) => {
                    return value.substring(0, 4)
                  }}
                  min={1}
                  // max={100}
                  onChange={(e) => {
                    {
                      setExpYear(e)
                    }
                  }}
                />
              </div>
            </div> */}
          </Modal>
        </>
      );
    }
  }

  const defaultLayoutPluginInstance = toolbarPlugin({
    toolbarPlugin: {
      fullScreenPlugin: {
        onEnterFullScreen: (zoom) => {
          zoom(SpecialZoomLevel.PageFit);
          defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
            ScrollMode.Wrapped
          );
        },
        onExitFullScreen: (zoom) => {
          zoom(SpecialZoomLevel.PageWidth);
          defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
            ScrollMode.Vertical
          );
        },
      },
    },
  });
  const { Toolbar } = defaultLayoutPluginInstance;

  const renderPage = (props) => (
    <>
      {props.canvasLayer.children}
      <div
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
          left: 0,
          position: "absolute",
          top: 0,
          width: "100%",
        }}
      >
        <div
          style={{
            color: "rgba(0, 0, 0, 0.2)",
            fontSize: `${8 * props.scale}rem`,
            fontWeight: "bold",
            textTransform: "uppercase",
            transform: "rotate(-45deg)",
            userSelect: "none",
          }}
        >
          ORME
        </div>
      </div>
      {props.annotationLayer.children}
      {props.textLayer.children}
    </>
  );

  const handleCancel = () => {
    setAggModal(false);
  };

  return (
    <>
      <div className="containerd p-0 ml-0">
        <div className="row">
          <div className="col-lg-6 col-xl-4">
            <div class="title-area">
              <h1 class="page-title">ORME Setup</h1>
            </div>
            <div className="stream-box-white">
              {!loading ? (
                <>
                  <div class="data-listing-box">
                    <h4 class="count-title">Agency Name</h4>
                    <h3 class="count">{validate?.payload?.data?.name ? validate?.payload?.data?.name : validate?.payload?.data?.company_name}</h3>
                  </div>
                  <div class="data-listing-box">
                    <h4 class="count-title">Fee</h4>
                    {/* <h3 class="count">30%</h3> */}
                    <h3 class="count">{validate?.payload?.data?.fee ? validate?.payload?.data?.fee : "0"}%</h3>
                  </div>
                </>
              ) : (
                <span className="spinclass loader-center position-relative">
                  <Spin size="large" />
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-xl-4">
            <div class="title-area">
              <h1 class="page-title">AGREEMENT</h1>
            </div>
            <div className="stream-box-white">
              {!loader ? (
                <>
                  <div class="data-listing-box d-flex align-items-center">
                    <h4 class="count-title">Agreement</h4>

                    <Button
                      className="default-btn  small-btn padt-4 w-initial m-0"
                      onClick={() => setAggModal(true)}
                    >
                      View
                    </Button>

                  </div>
                </>
              ) : (
                <span className="spinclass loader-center position-relative">
                  <Spin size="large" />
                </span>






              )}
            </div>
            {/* {loader ? (
              <span className="spinclass loader-center position-relative">
                <Spin size="large" />
              </span>
            ) : agreementData ? (
              <div
                className={"mt-3fdf"}
              >
                <div className="pdf-wrapper-main-view">
                  <div
                    className="rpv-core__viewer mid-widt max-114 pdf-wrapper"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      padding: "0",
                    }}
                  >
                    <div
                      className="toolbar-style"
                      style={{
                        alignItems: "center",
                        backgroundColor: "#eeeeee",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                        display: "flex",
                        padding: "0.25rem",
                      }}
                    >
                      <Toolbar>
                        {(props) => {
                          const {
                            // CurrentPageInput,
                            Download,
                            EnterFullScreen,
                            // GoToNextPage,
                            // GoToPreviousPage,
                            NumberOfPages,
                            Print,
                            ShowSearchPopover,
                            Zoom,
                            ZoomIn,
                            ZoomOut,
                          } = props;
                          return (
                            <>
                              <div style={{ padding: "0px 2px" }}>
                                <ShowSearchPopover />
                              </div>
                              <div style={{ padding: "0px 2px" }}>
                                <ZoomOut />
                              </div>
                              <div style={{ padding: "0px 2px" }}>
                                <Zoom />
                              </div>
                              <div style={{ padding: "0px 2px" }}>
                                <ZoomIn />
                              </div>
                              
                              <div
                                style={{
                                  padding: "0px 2px",
                                  textAlign: "center",
                                  flexGrow: "1",
                                }}
                              >
                                <NumberOfPages /> Pages
                              </div>
                             
                              <div
                                style={{
                                  padding: "0px 2px",
                                  marginLeft: "auto",
                                }}
                              >
                                <EnterFullScreen />
                              </div>
                              <div style={{ padding: "0px 2px" }}>
                                <Download />
                              </div>
                              <div style={{ padding: "0px 2px" }}>
                                <Print />
                              </div>
                            </>
                          );
                        }}
                      </Toolbar>
                    </div>
                    <div
                      className="scroll-box"
                      style={{
                        flex: 1,
                        overflow: "hidden",
                      }}
                    >
                      <div className="mid-widt max-114 pdf-style">
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                          <Viewer
                            fileUrl={agreementData?.ducument_url}
                            renderPage={renderPage}
                            plugins={[defaultLayoutPluginInstance]}
                          />
                        </Worker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <h3 className="text-center">Error in fetching Agreement File</h3>
            )
            } */}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-xl-4">
            {/* <div class="title-area">
              <h1 class="page-title">Terms of Use</h1>
            </div> */}
            <div className="stream-box-white">
              {!loader ? (
                <>
                  <div class="data-listing-box d-flex align-items-center">
                    <h4 class="count-title">Terms of Service</h4>


                    <a href="/terms-use" className="default-btn small-btn padt-4 w-initial m-0 mb-2" target="_blank">View</a>


                  </div>
                  <div class="data-listing-box d-flex align-items-center">
                    <h4 class="count-title">Privacy Policy</h4>


                    <a href="/privacy-policy" className="default-btn small-btn padt-4 w-initial m-0" target="_blank"> View</a>


                  </div>
                </>
              ) : (
                <span className="spinclass loader-center position-relative">
                  <Spin size="large" />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal-generic modal-1000"
        centered
        open={aggModal}
        // onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
        maskClosable={false}
        keyboard={false}
      >
        <h2 className="modal-hd1">Agreement</h2>
        <div
          className={"mt-3fdf"}
        >
          <div className="pdf-wrapper-main-view">
            <div
              className="rpv-core__viewer mid-widt max-114 pdf-wrapper"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                padding: "0",
              }}
            >
              <div
                className="toolbar-style"
                style={{
                  alignItems: "center",
                  backgroundColor: "#eeeeee",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  padding: "0.25rem",
                }}
              >
                <Toolbar>
                  {(props) => {
                    const {
                      // CurrentPageInput,
                      Download,
                      EnterFullScreen,
                      // GoToNextPage,
                      // GoToPreviousPage,
                      NumberOfPages,
                      Print,
                      ShowSearchPopover,
                      Zoom,
                      ZoomIn,
                      ZoomOut,
                    } = props;
                    return (
                      <>
                        <div style={{ padding: "0px 2px" }}>
                          <ShowSearchPopover />
                        </div>
                        <div style={{ padding: "0px 2px" }}>
                          <ZoomOut />
                        </div>
                        <div style={{ padding: "0px 2px" }}>
                          <Zoom />
                        </div>
                        <div style={{ padding: "0px 2px" }}>
                          <ZoomIn />
                        </div>

                        <div
                          style={{
                            padding: "0px 2px",
                            textAlign: "center",
                            flexGrow: "1",
                          }}
                        >
                          <NumberOfPages /> Pages
                        </div>

                        <div
                          style={{
                            padding: "0px 2px",
                            marginLeft: "auto",
                          }}
                        >
                          <EnterFullScreen />
                        </div>
                        <div style={{ padding: "0px 2px" }}>
                          <Download />
                        </div>
                        <div style={{ padding: "0px 2px" }}>
                          <Print />
                        </div>
                      </>
                    );
                  }}
                </Toolbar>
              </div>
              <div
                className="scroll-box"
                style={{
                  flex: 1,
                  overflow: "hidden",
                }}
              >
                <div className="mid-widt max-114 pdf-style">
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer
                      fileUrl={validate?.payload?.data?.agreement}
                      renderPage={renderPage}
                      plugins={[defaultLayoutPluginInstance]}
                    />
                  </Worker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
