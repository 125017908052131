import { GET_INVENTORY_ACTIVE } from "../../types/types";


export default function getInventoryActive(state = [], action) {
  switch (action.type) {
    case GET_INVENTORY_ACTIVE:
      return action.payload
    default:
      return state;
  }
}
