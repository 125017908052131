import { GET_ORDER_DETAILS, SHOPIFY_CUSTOMER_ORDER, GET_PURCHASER, GET_REFERRER, GET_CREATOR, GET_FILTER_USERS } from "../types/types";
import axios from "axios";

export const customerorderHistory =
  (page, limit, startDate, endDate, gender, category, subcategory, content_source, customer_id, channel, purchasedBy, ref_user, group_by, id, counter_type) =>
    (dispatch) => {

      const newData = {
        from_date: startDate,
        to_date: endDate,
        gen_category: gender ? gender : "",
        category: category ? category : "",
        sub_category: subcategory ? subcategory : "",
        content: content_source ? content_source : "",
        user_id: customer_id ? customer_id : "",
        channel: channel ? channel : "",
        purchasedBy: purchasedBy ? purchasedBy : "",
        ref_user: ref_user ? ref_user : "",
        group_by: group_by ? group_by : "",
      }
      if (counter_type === "review") {
        newData.review = id?.value
      } else {
        newData.event = id?.value
      }
      return axios({
        method: "POST",
        url: `agency/reports/getSalesReport?page=${page}&limit=${limit}`,
        // headers: { Authorization: `Bearer ${userInfo?.token}` },
        data: newData,
      })
        .then((response) => {
          dispatch({
            type: SHOPIFY_CUSTOMER_ORDER,
            payload: response.data,
          });
          return response.data;
        })
        .catch((error) => {
          return error;
        });
    };

export const details = (sales_tracker_id, sales_id) => (dispatch) => {
  return axios({
    method: "POST",
    url: `agency/reports/getSalesDetails`,
    data: sales_tracker_id
      ? {
        sales_tracker_id,
      }
      : { sales_id },
  })
    .then((response) => {
      dispatch({
        type: GET_ORDER_DETAILS,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getPurchaser = (name) => (dispatch) => {
  return axios({
    method: "POST",
    url: `agency/reports/getCustomers`,
    data: {
      name
    }
  })
    .then((response) => {
      dispatch({
        type: GET_PURCHASER,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getReferrer = (name) => (dispatch) => {
  return axios({
    method: "POST",
    url: `agency/reports/getReferers`,
    data: {
      name
    }
  })
    .then((response) => {
      dispatch({
        type: GET_REFERRER,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getCreator = (name) => (dispatch) => {
  return axios({
    method: "POST",
    url: `brand/reports/getcreator`,
    data: {
      name
    }
  })
    .then((response) => {
      dispatch({
        type: GET_CREATOR,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getFilterUsers = (name) => (dispatch) => {
  return axios({
    method: "POST",
    url: `brand/reports/getfilterusers`,
    data: {
      name
    }
  })
    .then((response) => {
      dispatch({
        type: GET_FILTER_USERS,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};