import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import { Button, Form, Input, InputNumber } from "antd";
import { DatePicker, Spin, Select, Empty } from "antd";
import { faCheck, faBank } from '@fortawesome/free-solid-svg-icons';
import {
    customerorderHistory,
    details,
    getPurchaser,
    getReferrer,
} from "../../redux/actions/purchaseHistory.action";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import AsyncSelect from "react-select/async";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "../../css/sales.scss";
import moment from "moment";
import numeral from "numeral";
import { getSavedCategories, getSavedGeneralCategories, getSavedSubCategories } from "../../redux/actions/newCategory.action";
import { searchEventID, searchReviewID, searchShowID } from "../../redux/actions/eventID.action";
import { stripePayout } from "../../redux/actions/connectStripe.action";
const { Option } = Select;
let arrHost = [];
const { RangePicker } = DatePicker;

export default () => {
    const [form] = Form.useForm();
    const dateFormat = "MM-DD-YYYY";
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const min_date = moment().startOf("year").format("YYYY-MM-DD");
    const max_date = moment(new Date()).format("YYYY-MM-DD");

    const [startDate, setStartDate] = useState(min_date);
    const [endDate, setEndDate] = useState(max_date);
    const [totalEvent, setTotalEvent] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [source, setSource] = useState("all");
    const [allCustomer, setAll] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [modal, setModal] = useState(false);
    const [flag, setFlag] = useState(false);
    const [status, setStatus] = useState(false);
    const [submit, setSubmit] = useState("none");
    const [customerId, setCustomerId] = useState();
    const [gender, setGender] = useState();
    const [category, setCategory] = useState();
    const [subCategory, setSubCategory] = useState();
    const [contentSource, setContentSource] = useState("all");
    const [channel, setChannel] = useState("all");
    const [load, setLoad] = useState(false);
    const [generalLoad, setGeneralLoad] = useState(false);
    const [catLoad, setCatLoad] = useState(false);
    const [subCatLoad, setSubCatLoad] = useState(false);
    const [order, setOrder] = useState();
    const [purchaseBy, setPurchaseBy] = useState();
    const [referredBy, setReferredBy] = useState();
    const [searchPurchaseBy, setSearchPurchaseBy] = useState([]);
    const [searchReferredBy, setsearchReferredBy] = useState([]);
    const [groupBy, setGroupBy] = useState("Order");
    const [groupByTable, setGroupByTable] = useState("Order");
    const [purchaseLoad, setPurchaseLoad] = useState(false);
    const [referrerLoad, setReferrerLoad] = useState(false);
    const [searchID, setSearchID] = useState();
    const [searchIDLoad, setSearchIDLoad] = useState("");
    const [searchDataIDs, setSearchDataIDs] = useState([]);
    const [item, setItem] = useState([]);
    const [data, setData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(false);
    const [previousPage, setPreviousPage] = useState(0);
    const [idType, setIdType] = useState("event");
    const { shopifyCustomerorder, detailsOrder, savedGeneralCategories, savedCategory, savedSubCategory } = useSelector(
        (state) => {
            return state;
        }
    );

    const limit = 8;
    let upperLimit = (currentPage + 1) * limit;
    const lowerLimit = upperLimit - limit + 1;
    const itemsPerPage = 15;
    const pageCount = Math.ceil(totalEvent / itemsPerPage);
    const getSum = (a1, a2) => parseFloat(a1) * parseFloat(a2);

    useEffect(() => {
        setSearchIDLoad(true)
        dispatch(
            stripePayout({ limit })
        ).then((res) => {
            setData(res?.message?.data);
            if (res?.message?.has_more) {
                setTotalRecords(true)
            }
            setLoading(false);
        });
    }, []);

    const dateRangePickerChanger = (value, dataString) => {
        const startDate = dataString[0];
        const endDate = dataString[1];
        setStartDate(startDate);
        setEndDate(endDate);
    };
    const handlePageClick = (e) => {
        // console.log(pageCount, 'pageCount');
        const page = e.selected;

        let last_id = ""
        let first_id = ""
        setCurrentPage(page);
        setLoading(true);
        if (previousPage === page) {
            first_id = data[0]?.id;
            last_id = null
        }
        else {
            last_id = data[data.length - 1].id
            first_id = null
        }
        dispatch(
            stripePayout(
                {
                    limit,
                    last_id,
                    first_id
                }
            )
        ).then((res) => {
            setLoading(false);
            setData(res?.message?.data);
            setPreviousPage(previousPage < 0 ? 0 : e.selected - 1)
            if (res?.message?.has_more) {
                setTotalRecords(true)
            }
            else {
                setTotalRecords(false)
                upperLimit = 0
            }
        });
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        dispatch(
            stripePayout({ limit })
        ).then((res) => {
            setData(res?.message?.data);
            if (res?.message?.has_more) {
                setTotalRecords(true)
            }
            setLoading(false);
        });


        // setSubmit(groupBy);
    };

    const detailView = (orderDetail) => {
        setItem(orderDetail)
        // setModal(true);
        // setLoad(true);
        // dispatch(details(orderDetail?.sales_tracker_id, orderDetail?._id)).then(
        //     (res) => {
        //         setLoad(false);
        //     }
        // );
    };

    const handleReset = (res) => {
        setIdType("event")
        setSearchID()
        setGroupByTable("Order")
        setSource("all");
        setContentSource("all")
        setChannel("all")
        setSubmit("none");
        setLoading(true);
        setCustomer([]);
        setGender(null);
        setCategory(null);
        setSubCategory(null);
        setCurrentPage(0);
        setOrder(null)
        setPurchaseBy(null)
        setReferredBy(null)
        setGroupBy("Order")
        const min_date = moment().startOf("year").format("YYYY-MM-DD");
        const max_date = moment(new Date()).format("YYYY-MM-DD");
        setStartDate(min_date);
        setEndDate(max_date);
        dispatch(
            customerorderHistory(
                1,
                limit,
                moment().startOf("year").format("YYYY-MM-DD"),
                moment(new Date()).format("YYYY-MM-DD"),
                "",
                "",
                "",
                "all",
                "",
                "all",
                "",
                "",
                "Order"
            )
        ).then((res) => {
            setLoading(false);
            setTotalEvent(res?.message?.total_records);
        });
    };

    const loadOptions = async (input, callback) => {
        await smartSearchFilter(input);

        const result = arrHost.filter((item) => {
            return {
                value: `${item.value}`,
                label: `${item.label}`,
            };
        });

        callback(result);
    };

    const smartSearchFilter = async (value) => {
        if (value.length > 0 && value.trim()) {
            await axios
                .post(`brand/reports/getCustomers`, {
                    name: value.trim(),
                })
                .then((response) => {
                    const loadHost = [];
                    const host = response.data.data;
                    if (host.length === 0) {
                        // notification.error({
                        //   message: "No Host Found",
                        //   className: "toast-error",
                        // });
                    }
                    setAll(host);
                    host.map((item) => {
                        return loadHost.push({
                            value: item?._id,
                            label: item?.first_name + " " + item?.last_name,
                        });
                    });
                    arrHost = loadHost;
                })
                .catch(function (error) {
                    // console.log(error);
                });
        }
    };

    useEffect(() => {
        document.body.classList.add("bioshop-body", "shopper-bioshop-body");
    }, []);

    const handleGender = (e) => {
        setCurrentPage(0);
        setGender(e);
        setCategory(null)
        setSubCategory(null)
        setCatLoad(true)
        dispatch(getSavedCategories(e)).then((res) => {
            if (res.success) {
                setCatLoad(false)
            } else {
                setCatLoad(false)
            }
        })

    };

    const handleCategory = (e) => {
        setCurrentPage(0);
        setCategory(e);
        setSubCategory(null)
        setSubCatLoad(true)
        dispatch(getSavedSubCategories(gender, e)).then((res) => {
            if (res.success) {
                setSubCatLoad(false)
            } else {
                setSubCatLoad(false)
            }
        })
    };

    const handleSubCategory = (e) => {
        setCurrentPage(0);
        setSubCategory(e);
    };

    const handleSource = (e) => {
        setSource(e);
    };

    const handleFilterPurchaseBy = (e) => {
        setPurchaseLoad(true)
        dispatch(getPurchaser(e)).then((res) => {
            setPurchaseLoad(false)
            if (res?.success) {
                setSearchPurchaseBy(res?.message)
            }
        })

    }

    const handleIDType = (e) => {
        setIdType(e)
        setSearchDataIDs([])
        setSearchID(null)

        switch (e) {
            case "event":
                return dispatch(searchEventID("", "event", startDate, endDate)).then((res) => {
                    setSearchIDLoad(false)
                    if (res?.success) {

                        setSearchDataIDs(res?.message)
                    }
                })
            case "show":
                return dispatch(searchShowID("", "show", startDate, endDate)).then((res) => {
                    setSearchIDLoad(false)
                    if (res?.success) {

                        setSearchDataIDs(res?.message)
                    }
                })
            case "review":
                return dispatch(searchReviewID("", "review", startDate, endDate)).then((res) => {
                    setSearchIDLoad(false)
                    if (res?.success) {

                        setSearchDataIDs(res?.message)
                    }
                })
        }
    }

    const handleReviewID = (e) => {
        setSearchIDLoad(true)
        switch (idType) {
            case "event":
                return dispatch(searchEventID(e.replaceAll("-", ''), "event", startDate, endDate)).then((res) => {
                    setSearchIDLoad(false)
                    if (res?.success) {

                        setSearchDataIDs(res?.message)
                    }
                })
            case "show":
                return dispatch(searchShowID(e.replaceAll("-", ''), "show", startDate, endDate)).then((res) => {
                    setSearchIDLoad(false)
                    if (res?.success) {

                        setSearchDataIDs(res?.message)
                    }
                })
            case "review":
                return dispatch(searchReviewID(e.replaceAll("-", ''), "review", startDate, endDate)).then((res) => {
                    setSearchIDLoad(false)
                    if (res?.success) {

                        setSearchDataIDs(res?.message)
                    }
                })
        }
    }

    const handleFilterReferredBy = (e) => {
        setReferrerLoad(true)
        dispatch(getReferrer(e)).then((res) => {
            setReferrerLoad(false)
            if (res?.success) {
                setsearchReferredBy(res?.message)
            }
        })

    }
    // This is componentWillUnmount
    useEffect(() => {
        return () => {
            //  componentWillUnmount.current = true;
            document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
        };
    }, []);

    function Summary() {
        return (
            <div className="sales-summery mb-3">
                <div className="row">
                    <div className="col-lg-6 col-xl-4 mb-2">
                        <div className="summarry-box new-style top-space">
                            <h4 className="summary-hd">Summary</h4>
                            <div className="col-12 summary-detail-box">
                                <h4 className="count-title">Orders</h4>
                                <h3 className="count">
                                    {
                                        shopifyCustomerorder?.payload?.message?.summary[0]
                                            ?.order_count
                                    }
                                </h3>
                            </div>
                            <div className="col-12 summary-detail-box">
                                <h4 className="count-title">Total Gross Sales</h4>
                                <h3 className="count">
                                    {numeral(
                                        shopifyCustomerorder?.payload?.message?.summary[0]
                                            ?.order_totalprice
                                    ).format("$0,0.0'")}
                                </h3>
                            </div>
                            <div className="col-12 summary-detail-box">
                                <h4 className="count-title">Total Return Amount</h4>
                                <h3 className="count">
                                    {numeral(
                                        shopifyCustomerorder?.payload?.message?.summary[0]
                                            ?.returned_amount
                                    ).format("$0,0.0'")}
                                </h3>
                            </div>
                            <div className="col-12 summary-detail-box">
                                <h4 className="count-title">Total Balance Amount</h4>
                                <h3 className="count">
                                    {numeral(
                                        shopifyCustomerorder?.payload?.message?.summary[0]
                                            ?.balance_amount
                                    ).format("$0,0.0'")}
                                </h3>
                            </div>
                            <div className="col-12 summary-detail-box">
                                <h4 className="count-title">Total ORME Fee</h4>
                                <h3 className="count">
                                    {numeral(
                                        shopifyCustomerorder?.payload?.message?.summary[0]?.kb_fee
                                    ).format("$0,0.0'")}
                                </h3>
                            </div>
                            <div className="col-12 summary-detail-box">
                                <h4 className="count-title">Total Net Sales</h4>
                                <h3 className="count">
                                    {numeral(
                                        shopifyCustomerorder?.payload?.message?.summary[0]?.net_brand_amount
                                    ).format("$0,0.0'")}
                                </h3>
                            </div>
                            {/* <div className="col-12 summary-detail-box">
                <h4 className="count-title">Total Cashback</h4>
                <h3 className="count">
                  {numeral(
                    shopifyCustomerorder?.payload?.message?.summary[0]
                      ?.cashback_amount
                  ).format("$0,0.0'")}
                </h3>
              </div>
              <div className="col-12 summary-detail-box">
                <h4 className="count-title">Total Referral Fee</h4>
                <h3 className="count">
                  {numeral(
                    shopifyCustomerorder?.payload?.message?.summary[0]
                      ?.customer_commission
                  ).format("$0,0.0'")}
                </h3>
              </div>
              <div className="col-12 summary-detail-box">
                <h4 className="count-title">Total Content Fee</h4>
                <h3 className="count">
                  {numeral(
                    shopifyCustomerorder?.payload?.message?.summary[0]
                      ?.creator_commission
                  ).format("$0,0.0'")}
                </h3>
              </div> */}

                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function DefaultTable() {
        // let data = shopifyCustomerorder?.payload?.message?.data;

        if (data) {
            switch (groupByTable) {
                default:
                    return (<>
                        <div className="table-responsive scrollbar-style">
                            <table className="transactions-box table">
                                <thead className="table_heading">
                                    <tr>
                                        <th className="td-width-60">S.#</th>
                                        <th>Payout ID</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Delivery Method</th>
                                        <th>Description</th>

                                        {/* <th className="td-width-300" colSpan={3}>Bank/card</th> */}
                                        <th>Date Paid</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        shopifyCustomerorder.loading ? (
                                            <div className="antd-loading-ift">
                                                <Spin size="large"></Spin>
                                            </div>
                                        )
                                            : data.length === 0 ? (
                                                <tr>
                                                    <td colspan="7">
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                    </td>
                                                </tr>
                                            )
                                                : (
                                                    data?.map((item, i) => {
                                                        return (
                                                            <tr
                                                                className="open-modal cursor-pointer"
                                                                onClick={() => {
                                                                    detailView(item);
                                                                }}
                                                            >
                                                                <td>{i + 1 + currentPage * limit}</td>
                                                                <td>{item?.id}</td>
                                                                <td className="social-header-hd-area justify-content-start align-items-center pb-0 mb-1">
                                                                    {/* <a
                                                            className="open-modal blue-link"
                                                            onClick={() => {
                                                                detailView(item);
                                                            }}
                                                        > */}
                                                                    <div className="order-num td-width-60 text-right">
                                                                        US{numeral(item?.amount / 100).format("$0,0.0'")}
                                                                    </div>
                                                                    {/* </a> */}
                                                                    <span>USD</span>
                                                                    {/* <div className="connection-status-badge-green firstLetterCapital">{item?.status} {" "} */}
                                                                    {/* <FontAwesomeIcon icon={faCheck} /> */}
                                                                    {/* </div> */}

                                                                </td>
                                                                <td>
                                                                    <div className="social-header-hd-area m-0">

                                                                        <div className="connection-status-badge-green firstLetterCapital">{item?.status} {" "}
                                                                            {/* <FontAwesomeIcon icon={faCheck} /> */}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                {/* <td>
                                                                    <div className="social-header-hd-area justify-content-start align-items-center pb-0 mb-1">
                                                                       
                                                                        <span className=" limit-line">
                                                                            {item?.source?.id}
                                                                        </span>
                                                                    </div>
                                                                </td> */}
                                                                <td className="firstLetterCapital">
                                                                    {item?.method}
                                                                </td>
                                                                <td className="firstLetterCapital">
                                                                    {item?.description}
                                                                </td>
                                                                <td>
                                                                    {" "}
                                                                    {moment.unix(item?.arrival_date).format("MM-DD-YYYY hh:mm A")}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                )}

                                    {data.length > 0 && (
                                        <tr>
                                            <td></td>
                                            <td>
                                                <b>Total</b>
                                            </td>
                                            <td>
                                                <b>
                                                    US{numeral(
                                                        data.reduce((a, b) => {
                                                            return (
                                                                Number(a) +
                                                                Number(
                                                                    b.amount ? (b.amount / 100) : 0.0
                                                                )
                                                            );
                                                        }, 0)
                                                    ).format("$0,0.0'")}{" "}USD
                                                </b>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>


                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </>
                    );

            }
        }
    }

    return (
        <>
            <div className="sales-page-main">
                <div className="brand_container_main aff-payment">
                    {/* <hr className="separator-line mt-0" /> */}
                    <div className="purchase-data referral-data">
                        {loading ? (
                            // <div className="antd-loading-ift">
                            // 	<Spin size="large" />
                            // </div>
                            <div className="loading-wrap">
                                <span className="spinclass loader-center position-relative">
                                    <Spin size="large" />
                                </span>
                            </div>
                        ) : (
                            <>
                                {submit === "none" && <DefaultTable />}
                                {/* {submit === "date" && <DateGroupTable />} */}
                                {/* {submit === "category" && <CategoryGroupTable />}
                {submit === "brand" && <BrandGroupTable />} */}
                                <div className="purchase-data referral-data">
                                    {data?.length ? (
                                        <ReactPaginate
                                            nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                                            // pageCount={Math.ceil(totalRecords ? (data?.length * 2) / 8 : (data?.length) / 8)}
                                            pageCount={Math.ceil(data?.length === 8 ? upperLimit * 2 / 8 : currentPage + 1)}
                                            forcePage={currentPage}
                                            previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                                            pageClassName="page-item d-none"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item d-none"
                                            breakLinkClassName="page-link"
                                            containerClassName={"pagination custom-paginate"}
                                            activeClassName="active"
                                            renderOnZeroPageCount={null}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                                {/* {shopifyCustomerorder?.payload?.message?.data?.length > 0 && (
                                    <Summary />
                                )} */}
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/* <MyModal showModal={modal} dynamicData={item} /> */}

            <Modal
                dialogClassName="code-activated-popup"
                size="xl"
                scrollable
                show={modal}
                onHide={() => setModal(false)}
                centered
                backdrop="static"
            >
                <Modal.Header className="modal-header-style2" closeButton>
                    <Modal.Title className="mb-0">Payment Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {load ? (
                        <span className="mt-2 mr-2 loader-center position-relative">
                            <Spin size="large" />
                        </span>
                    ) : (
                        <>
                            <div className="order-detail-title-area">
                                <div className="d-flex flex-column">
                                    <div className="order-num social-header-hd-area justify-content-start align-items-center pb-0 mb-1">
                                        <h4 className="mb-0">
                                            US{numeral(item?.amount / 100).format("$0,0.0'")}USD
                                        </h4>
                                        <div className="connection-status-badge-green firstLetterCapital">{item?.status} {" "}
                                            {/* <FontAwesomeIcon icon={faCheck} /> */}
                                        </div>
                                        {/* <div className="order-name">
                                            {
                                                item?._id
                                            }
                                        </div> */}
                                    </div>
                                    <br />
                                    <duv className="order-num order-name-main">
                                        Account details
                                        {/* <div className="order-name">
                                            {item?.customer ? item?.customer : "None"}
                                        </div> */}
                                    </duv>
                                </div>
                                <span className="order-date">
                                    {moment
                                        .unix(
                                            item?.created
                                        )
                                        .format("lll")}
                                </span>

                            </div>
                            <hr className="separator-line mt-0" />

                            <div className="order-detail-title-area">

                                <div className="social-header-hd-area justify-content-start align-items-center pb-0 mb-1 ml-2">
                                    <div className="connection-status-badge-grey">
                                        <FontAwesomeIcon icon={faBank} />
                                    </div>
                                    <span className=" limit-line">
                                        {item?.source?.id}
                                    </span>
                                </div>
                                <span>{item?.currency}</span>
                            </div>
                            <br />
                            <div className="order-detail-title-area">
                                <duv className="order-num order-name-main">
                                    Summary
                                    {/* <div className="order-name">
                                            {item?.customer ? item?.customer : "None"}
                                        </div> */}
                                </duv>
                            </div>
                            <hr className="separator-line mt-0" />

                            <Table responsive size="sm" className="transactions-box">
                                <thead className="table_heading">
                                    <tr>
                                        <th className="td-width-300"></th>
                                        <th className="text-right">Count</th>
                                        <th className="text-right">Gross</th>
                                        <th className="text-right">Fees</th>
                                        <th className="text-right">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {!detailsOrder?.loading &&
                                        detailsOrder?.payload?.message?.order_detail?.line_items?.map(
                                            (item, key) => {
                                                return ( */}
                                    <tr>
                                        <td>{1}</td>
                                        <td className="text-right">
                                            {moment
                                                .unix(item?.created)
                                                .format('MM-DD-YYYY')}
                                        </td>
                                        <td className="text-right">{item?.source?.id}</td>
                                        <td className="text-right">{item?.id}</td>
                                        <td className="text-right">
                                            {numeral(
                                                item?.amount / 100
                                            ).format("$0,0.0'")}
                                        </td>
                                    </tr>


                                    {/* summary */}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className="text-right">
                                            <b>Payouts</b>
                                        </td>
                                        <td className="text-right">
                                            <b>
                                                US{numeral(
                                                    data.reduce((a, b) => {
                                                        return (
                                                            Number(a) +
                                                            Number(
                                                                b.amount ? (b.amount / 100) : 0.0
                                                            )
                                                        );
                                                    }, 0)
                                                ).format("$0,0.0'")}
                                            </b>
                                        </td>



                                    </tr>
                                </tbody>
                            </Table>

                            {/* Transactions */}
                            <div className="order-detail-title-area">
                                <duv className="order-num order-name-main">
                                    Transactions
                                    {/* <div className="order-name">
                                            {item?.customer ? item?.customer : "None"}
                                        </div> */}
                                </duv>
                            </div>
                            <hr className="separator-line mt-0" />

                            <Table responsive size="sm" className="transactions-box">
                                <thead className="table_heading">
                                    <tr>
                                        <th className="td-width-300"></th>
                                        <th className="text-right">Count</th>
                                        <th className="text-right">Gross</th>
                                        <th className="text-right">Fees</th>
                                        <th className="text-right">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {!detailsOrder?.loading &&
                                        detailsOrder?.payload?.message?.order_detail?.line_items?.map(
                                            (item, key) => {
                                                return ( */}
                                    <tr>
                                        <td>{1}</td>
                                        <td className="text-right">
                                            {moment
                                                .unix(item?.created)
                                                .format('MM-DD-YYYY')}
                                        </td>
                                        <td className="text-right">{item?.source?.id}</td>
                                        <td className="text-right">{item?.id}</td>
                                        <td className="text-right">
                                            {numeral(
                                                item?.amount / 100
                                            ).format("$0,0.0'")}
                                        </td>
                                    </tr>


                                    {/* summary */}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className="text-right">
                                            <b>Payouts</b>
                                        </td>
                                        <td className="text-right">
                                            <b>
                                                US{numeral(
                                                    data.reduce((a, b) => {
                                                        return (
                                                            Number(a) +
                                                            Number(
                                                                b.amount ? (b.amount / 100) : 0.0
                                                            )
                                                        );
                                                    }, 0)
                                                ).format("$0,0.0'")}
                                            </b>
                                        </td>



                                    </tr>
                                </tbody>
                            </Table>


                            <div className="order-sum">
                                <div className="paid-mid-row">
                                    <div className="left-txt multi-content">
                                        <div className="left-hd">Amount</div>
                                        {/* <div className="left-info">
                                            {
                                                detailsOrder?.payload?.message?.order_detail?.line_items
                                                    ?.length
                                            }{" "}
                                            item
                                        </div> */}
                                    </div>
                                    <div className="right-txt">
                                        {numeral(
                                            item?.amount / 100
                                        ).format("$0,0.0'")}
                                    </div>
                                </div>

                                <div className="paid-mid-row">
                                    <div className="left-txt multi-content">
                                        <div className="left-hd">Fee</div>

                                    </div>
                                    <div className="right-txt">
                                        {numeral(item?.application_fee_amount / 100).format("$0,0.0'")}
                                    </div>
                                </div>

                                <div className="paid-mid-row">
                                    <div className="left-txt multi-content">
                                        <div className="left-hd">Net</div>
                                    </div>
                                    <div className="right-txt">
                                        {numeral(
                                            (item?.amount - item?.application_fee_amount) / 100
                                        ).format("$0,0.0'")}
                                    </div>
                                </div>

                                <div className="paid-mid-row">
                                    <div className="left-txt multi-content">
                                        <div className="left-hd">Status</div>
                                    </div>
                                    <div className="right-txt">
                                        {item?.status}
                                    </div>
                                </div>
                                <div className="paid-mid-row">
                                    <div className="left-txt multi-content">
                                        <div className="left-hd">Description</div>
                                    </div>
                                    <div className="right-txt">
                                        {item?.description ? item?.description : "None"}
                                    </div>
                                </div>
                                {/* <div className="paid-mid-row">
                                    <div className="left-txt multi-content">
                                        <div className="left-hd fw-bold">Total Amount</div>
                                    </div>
                                    <div className="right-txt fw-bold">
                                        {numeral(
                                            detailsOrder?.payload?.message?.order_detail?.total_price
                                        ).format("$0,0.0'")}
                                    </div>
                                </div> */}
                            </div>
                            {!detailsOrder?.loading &&
                                detailsOrder?.payload?.message?.order_return_detail && (
                                    <div style={{ marginTop: "20px" }}>
                                        <div className="order-detail-title-area">
                                            <div className="d-flex flex-column">
                                                <div className="order-num">Refund Detail</div>
                                            </div>
                                        </div>
                                        <Table responsive size="sm" className="transactions-box">
                                            <thead className="table_heading">
                                                <tr>
                                                    <th>S#</th>
                                                    <th>Order Date</th>
                                                    <th>SKU #</th>
                                                    <th>Description</th>
                                                    <th>Qty</th>
                                                    <th>Price</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!detailsOrder?.loading &&
                                                    detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.map(
                                                        (item, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td>{key + 1}</td>
                                                                    <td>
                                                                        {moment
                                                                            .utc(order?.created_at)
                                                                            .format("MM-DD-YYYY")}
                                                                    </td>
                                                                    <td>{item?.line_item?.sku}</td>
                                                                    <td>{item?.line_item?.title}</td>
                                                                    <td>{item?.quantity}</td>
                                                                    <td>
                                                                        {numeral(
                                                                            item?.line_item?.price
                                                                        ).format("$0,0.0'")}
                                                                    </td>
                                                                    <td>
                                                                        {numeral(item?.subtotal).format("$0,0.0'")}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                            </tbody>
                                        </Table>
                                        <div className="order-sum">
                                            <>
                                                <div className="paid-mid-row">
                                                    <div className="left-txt multi-content">
                                                        <div className="left-hd">Subtotal</div>
                                                        <div className="left-info">
                                                            {detailsOrder?.payload?.message?.order_return_detail
                                                                ?.refund_line_items?.length
                                                                ? detailsOrder?.payload?.message
                                                                    ?.order_return_detail?.refund_line_items?.length
                                                                : 0}{" "}
                                                            item
                                                        </div>
                                                    </div>
                                                    <div className="right-txt">
                                                        {numeral(
                                                            detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                                                (acc, item) => {
                                                                    return (acc = acc + item.subtotal);
                                                                },
                                                                0
                                                            )
                                                        ).format("$0,0.0'")}
                                                    </div>
                                                </div>
                                                <div className="paid-mid-row">
                                                    <div className="left-txt multi-content">
                                                        <div className="left-hd">Tax</div>
                                                    </div>
                                                    <div className="right-txt">
                                                        {numeral(
                                                            detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                                                (acc, item) => {
                                                                    return (acc = acc + item.total_tax);
                                                                },
                                                                0
                                                            )
                                                        ).format("$0,0.0'")}
                                                    </div>
                                                </div>
                                                <div className="paid-mid-row">
                                                    <div className="left-txt multi-content">
                                                        <div className="left-hd fw-bold">Total Amount</div>
                                                    </div>
                                                    <div className="right-txt fw-bold">
                                                        {numeral(
                                                            detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                                                (acc, item) => {
                                                                    return (acc = acc + item.subtotal);
                                                                },
                                                                0
                                                            ) +
                                                            detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                                                (acc, item) => {
                                                                    return (acc = acc + item.total_tax);
                                                                },
                                                                0
                                                            )
                                                        ).format("$0,0.0'")}
                                                    </div>
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                )}
                        </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        onClick={() => setModal(false)}
                        className="default-btn outline padb-3"
                    >
                        <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Go
                        Back
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
