import {
  CHECK_INSTAGRAM_USER_REQUEST, CHECK_INSTAGRAM_USER_SUCCESS, CHECK_INSTAGRAM_USER_ERROR
} from '../../types/types';

export default function checkInstagramUser(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case CHECK_INSTAGRAM_USER_REQUEST:
      return {
        loading: true,
      };
    case CHECK_INSTAGRAM_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case CHECK_INSTAGRAM_USER_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    default:
      return state;
  }
}
