import { BRAND_FOLLOWERS } from "../../types/types";
export default function getBrandFollowers(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case BRAND_FOLLOWERS:
            return {
                ...state,
                payload: payload,
            };
        default:
            return state;
    }
}