import {
  GET_ALL_CATEGORIES_REQUEST,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_ALL_CATEGORIES_ERROR,
} from '../../types/types';

export default function allCategory(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_CATEGORIES_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    case GET_ALL_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    default:
      return state;
  }
}
