import React, { useState, useEffect, useRef } from "react";
import "antd/dist/antd.css";
import "../css/custom.scss";
import {
  Route,
  Link,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import logo from "../images/ormelogo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStore,
  faXmark,
  faGears,
  faUserTie,
  faGauge,
  faNoteSticky,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import { PercentProvider } from "../context/PercentProvider";
import { createBrowserHistory } from "history";
import BroadCast from "../pages/broadcast/broadcast";
import { Layout, Menu, notification, Spin } from "antd";
import Footer from "../components/footer/footer";
import MainHeader from "../components/header/header";
import useEscape from "../reUsable/useEscapeKey";
import Dashboard from "../pages/dashboard/index";
import HomeRole from "../pages/home/role";
import BasicSetup from "../pages/basicSetup/basicSetup";
import PublicPrivacyPolicy from "../pages/public/public-privacy-policy";
import PublicTermsUse from "../pages/public/public-terms-use";
import { useDispatch, useSelector } from "react-redux";
import DirectAuth from "../pages/public/direct-auth";
import KbSetup from "../pages/kbSetup/kbSetup";
import InviteTalent from "../pages/events/inviteTalent";
import TotalOrder from "../pages/salesReport/totalOrder";
import InviteLogs from "../pages/events/invite-logs";
import PayoutSetup from "../pages/kbSetup/payoutSetup";

const { SubMenu } = Menu;
const { Sider, Content } = Layout;
export const history = createBrowserHistory({
  forceRefresh: true,
});

const App = () => {
  const { validate, getProduct } = useSelector((state) => {
    return state;
  });
  const ref = useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  const [storeMenu, setStoreMenu] = useState(false);

  const [toggle, setToggle] = useState(false);
  const [userInfoData, setUserInfoData] = useState("");

  const [title, setTitle] = useState(location.pathname);

  let instagramCodeUrl = new URL(window.location.href);
  let code = new URLSearchParams(instagramCodeUrl.search);
  const notificationKey = `open${Date.now()}`;
  const load = <div className="d-flex"><span className="spin-color mx-2">
    <Spin size="small" />
  </span><p>Your products are syncing</p></div>;

  const reRender = (userData) => {
    setUserInfoData(userData);
  };
  useEffect(() => {
    if (
      location.pathname === "/account/total-orders"
      // || location.pathname === "/instagram-reels" 
    ) {
      changetitle(location.pathname);
    }
    else {
      changetitle(location.pathname.split("/")[1]);
    }
  }, [location]);
  useEffect(() => {
    mobileSidebar(toggle);
  }, [toggle]);

  function Redirect({ to }) {
    let navigate = useNavigate();
    useEffect(() => {
      navigate(to);
    });
    return null;
  }

  const storeClick = () => {
    if (storeMenu) {
      setStoreMenu(false);
    } else {
      setStoreMenu(true);
    }
  };

  const mobileSidebar = (action) => {
    setToggle(action);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (storeMenu && ref.current && !ref.current.contains(e.target)) {
        setStoreMenu(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [storeMenu]);
  const changetitle = (title) => {
    if (title === "invite") {
      setTitle("Talent List");
    } else if (title === "inviteLogs") {
      setTitle("Invite Logs");
    } else if (title === "brandsetup") {
      setTitle("Settings");
    } else if (title === "payout") {
      setTitle("Settings");
    } else if (title === "basicsetup") {
      setTitle("Settings");
    } else if (title === "basicsetup") {
      setTitle("Settings");
    } else if (title === "privacy-policy") {
      setTitle("Privacy Policy");
    } else if (title === "terms-use") {
      setTitle("Terms & Conditions");
    } else if (title === "dashboard") {
      setTitle("Dashboard");
    } else if (title === "/account/total-orders") {
      setTitle("Total Sales");
    }
  };
  const rootSubmenuKeys = [
    "accounting",
    "settings",
  ];
  const [openKeys, setOpenKeys] = useState([]);
  useEffect(() => {
    if (location?.state?.openKey) {
      setOpenKeys([location?.state?.openKey])
    }
  }, [location?.state])
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEscape(() => setToggle(false));

  return (
    <PercentProvider>
      <Layout className="generic-style">
        <>
          <Sider
            className={`sidebar-main scrollbar-style ${!toggle ? "toggle-sidebar" : ""
              } `}
          >
            <div className="logo-area">
              <div className="logo">
                <Link
                  to="/dashboard1"
                  onClick={() => {
                    changetitle("dashboard");
                    setToggle(false);
                    onOpenChange([])
                  }}
                >
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <div className="menu-close d-xl-none">
                <Link
                  to="#"
                  onClick={() => {
                    setToggle(false);
                  }}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </Link>
              </div>
            </div>
            <h5 className="wordspace-hd">Workspace</h5>
            <div
              className="workspace-menu"
              ref={ref}
              onClick={() => storeClick()}
            >
              <div className="store-menu">
                <div className="workspace-icon">
                  <FontAwesomeIcon icon={faStore} />
                </div>
                <div className="workspace-menu-item">
                  {/* <div className="store-name-txt">{userInfoo?.message?.data?.name}</div> */}
                  <div className="store-name-txt">
                    {validate?.payload?.data?.name ? validate?.payload?.data?.name : validate?.payload?.data?.company_name}
                  </div>
                  {/* <div className="store-owner-txt">{userInfo?.name}</div> */}
                </div>
              </div>
            </div>
            <Menu
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              mode="inline"
              className="menu-style-new"
            // defaultSelectedKeys={location.pathname.split("/").length > 2 ? location.pathname.split("/")[2] : location.pathname.split("/")[1]}
            // defaultSelectedKeys={["/account/ecommerce"]}
            >
              <Menu.Item key="dashboard"
                className={`${(title === "Dashboard") &&
                  "ant-menu-item-selected"
                  }`}
              >
                <Link
                  to="dashboard"
                  onClick={() => {
                    changetitle("dashboard");
                    setToggle(false);
                    onOpenChange([])
                  }}
                >
                  <FontAwesomeIcon icon={faGauge} />
                  <span className="nav-text">Dashboard</span>
                </Link>
              </Menu.Item>
              <Menu.Item
                key="invite"
                className={`${(title === "Talent List") &&
                  "ant-menu-item-selected"
                  }`}
              >
                <Link
                  to="invite"
                  onClick={() => {
                    changetitle("invite");
                    setToggle(false);
                    onOpenChange([])
                  }}
                >
                  <FontAwesomeIcon className="" icon={faUserTie} />
                  <span className="nav-text">Talent List</span>
                </Link>
              </Menu.Item>
              <Menu.Item
                key="inviteLogs"
                className={`${(title === "Invite Logs") &&
                  "ant-menu-item-selected"
                  }`}
              >
                <Link
                  to="inviteLogs"
                  onClick={() => {
                    changetitle("inviteLogs");
                    setToggle(false);
                    onOpenChange([])
                  }}
                >
                  <FontAwesomeIcon className="" icon={faUser} />
                  <span className="nav-text">Invite Logs</span>
                </Link>
              </Menu.Item>
              <SubMenu
                className="submenu-main"
                key="accounting"
                title={
                  <span>
                    <span class="fa fa-line-chart menu-icon-direct"></span>
                    <span className="nav-text-color">Sales Report</span>
                  </span>
                }
              >
                <Menu.Item key="total-orders">
                  <Link
                    className={`${(title !== "Total Sales" && "unSelectedMenu")}`}
                    to="/account/total-orders"
                    onClick={() => {
                      changetitle("sales");
                      setToggle(false);
                    }}
                  >
                    Total Sales
                  </Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                className="submenu-main bottom-fixed-aut"
                key="settings"
                title={
                  <span>
                    <FontAwesomeIcon icon={faGears} />
                    <span className="nav-text-color">Settings</span>
                  </span>
                }
              >
                <Menu.Item key="brandsetup">
                  <Link
                    className={`${(title !== "Settings" && "unSelectedMenu")}`}
                    to="brandsetup"
                    onClick={() => {
                      changetitle("brandsetup");
                      setToggle(false);
                    }}
                  >
                    ORME Setup
                  </Link>
                </Menu.Item>
                <Menu.Item key="payout">
                  <Link
                    className={`${(title !== "Settings" && "unSelectedMenu")}`}
                    to="payout"
                    onClick={() => {
                      changetitle("payout");
                      setToggle(false);
                    }}
                  >
                    Payment Setup
                  </Link>
                </Menu.Item>
                <Menu.Item key="basicsetup">
                  <Link
                    className={`${(title !== "Settings" && "unSelectedMenu")}`}
                    to="basicsetup"
                    onClick={() => {
                      changetitle("basicsetup");
                      setToggle(false);
                    }}
                  >
                    Basic Setup
                  </Link>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <MainHeader
              title={title}
              toggle1={toggle}
              mobileSidebar={mobileSidebar}
            />
            <Content className="dashboard-content-container">
              <div className="dashboard-content-full">
                <div
                  className={`dashboard-inner-content ${title === "Recorded Event" ? "recorded-room-width" : ""
                    } ${title === "Social Store" ? "store-style" : ""}`}
                >
                  <Routes>
                    <Route path="/home" exact element={<HomeRole />} />
                    <Route path="/auth/:token" element={<DirectAuth />} />
                    <Route
                      path="/dashboard"
                      exact
                      element={<Dashboard />}
                    />
                    <Route
                      path="/dashboard1"
                      element={<Redirect to="/dashboard" />}
                    />
                    <Route path="/invite" exact element={<InviteTalent />} />
                    <Route path="/inviteLogs" exact element={<InviteLogs />} />
                    <Route
                      path="/account/total-orders"
                      exact
                      element={<TotalOrder />}
                    />
                    <Route
                      path="/privacy-policy"
                      element={<PublicPrivacyPolicy />}
                    />
                    <Route path="/terms-use" element={<PublicTermsUse />} />
                    <Route path="*" element={<Redirect to="/dashboard" />} />
                    <Route path="/brandsetup" exact element={<KbSetup />} />
                    <Route path="/payout" exact element={<PayoutSetup />} />
                    <Route
                      path="/basicsetup"
                      exact
                      element={<BasicSetup reRender={reRender} />}
                    />
                  </Routes>
                </div>

                <Footer />
              </div>
            </Content>
          </Layout>
        </>
      </Layout>
    </PercentProvider>
  );
};

export default App;
