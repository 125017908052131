import {GET_USERPROFILE } from "../../types/types";

const initialState = {message:[]}

export default function monitorProfileUser(state = initialState, action) {
  switch (action.type) {
    case GET_USERPROFILE:
        return action.payload
    default:
      return state;
  }
}
