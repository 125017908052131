import {
  INVITE_TALENT_REQUEST,
  INVITE_TALENT_SUCCESS,
  INVITE_TALENT_ERROR,
  GET_INVITE_TALENT_REQUEST,
  GET_INVITE_TALENT_SUCCESS,
  GET_INVITE_TALENT_ERROR,
  GET_INVITE_USER_REQUEST,
  GET_INVITE_USER_SUCCESS,
  GET_INVITE_USER_ERROR,
  ACCEPT_OR_REJECT_DISSOCIATION_REQUEST,
  ACCEPT_OR_REJECT_DISSOCIATION_SUCCESS,
  ACCEPT_OR_REJECT_DISSOCIATION_ERROR,
} from '../types/types';
import axios from 'axios';

export const inviteTalent = (data) => (dispatch) => {
  dispatch({ type: INVITE_TALENT_REQUEST });
  console.log(data, 'data data');
  return axios({
    method: 'POST',
    url: `agency/sendEmail`,
    data: data,
  })
    .then((response) => {
      return dispatch({
        type: INVITE_TALENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: INVITE_TALENT_ERROR,
        payload: error.response,
      });
    });
};

export const getInvitedTalent = (page, limit, start_date, end_date, email) => (dispatch) => {
  dispatch({ type: GET_INVITE_TALENT_REQUEST });

  return axios({
    method: 'POST',
    url: `agency/getEmails?limit=${limit}&page=${page}`,
    data: {
      from_date: start_date,
      to_date: end_date,
      email,
    }
  })
    .then((response) => {
      return dispatch({
        type: GET_INVITE_TALENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_INVITE_TALENT_ERROR,
        payload: error.response,
      });
    });
};

export const getInvitedUser = (page, limit, start_date, end_date, name, pixel_id, phone, email, status, is_active) => (dispatch) => {
  dispatch({ type: GET_INVITE_USER_REQUEST });

  return axios({
    method: 'POST',
    // url: `agency/getUsers?limit=${limit}&page=${page}`,
    url: `agency/getUserAssociatedListForAgency?limit=${limit}&page=${page}`,
    data: {
      from_date: start_date,
      to_date: end_date,
      name,
      pixel_id,
      phone,
      email,
      // is_active,
      status,
      is_active
    }
  })
    .then((response) => {
      return dispatch({
        type: GET_INVITE_USER_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_INVITE_USER_ERROR,
        payload: error.response,
      });
    });
};

export const acceptOrRejectDissociation = (data) => (dispatch) => {
  dispatch({ type: ACCEPT_OR_REJECT_DISSOCIATION_REQUEST });
  console.log(data, 'data data');
  return axios({
    method: 'POST',
    url: `agency/acceptOrRejectDissociation`,
    data: data,
  })
    .then((response) => {
      return dispatch({
        type: ACCEPT_OR_REJECT_DISSOCIATION_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: ACCEPT_OR_REJECT_DISSOCIATION_ERROR,
        payload: error.response,
      });
    });
};
