import {
  UPDATE_ACCESS_TOKEN_REQUEST,
  UPDATE_ACCESS_TOKEN_SUCCESS,
  UPDATE_ACCESS_TOKEN_ERROR,
} from '../../types/types';

export default function updateAccessToken(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_ACCESS_TOKEN_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case UPDATE_ACCESS_TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    default:
      return state;
  }
}
