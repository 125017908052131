import { GET_ORDER_REQUEST, GET_ORDER_SUCCESS, GET_ORDER_ERROR } from "../../types/types";
export default function getOrder(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_ORDER_REQUEST:
			return {
				loading: true,
			};
		case GET_ORDER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case GET_ORDER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}