import { REACT_S3} from "../../types/types";
export default function reactS3BucketData(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case REACT_S3:
      return {
        ...state,
        payload: payload,
      };
    default:
      return state;
  }
}