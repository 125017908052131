import {
    GET_MARKET_FEE
} from "../../types/types";
export default function marketFeeView(state = "", action) {
	const { type, payload } = action;
	switch (type) {
		case GET_MARKET_FEE:
			return {
				...state,
				loading: false,
				success: false,
				payload: payload,
			};
		default:
			return state;
	}
}