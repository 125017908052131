import {
  GET_BROADCAST_CLIENT_REQUEST,
  GET_BROADCAST_CLIENT_SUCCESS,
  GET_BROADCAST_CLIENT_ERROR,
} from "../../types/types";

export default function getBroadcastClient(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_BROADCAST_CLIENT_REQUEST:
      return {
        loading: true,
      };
    case GET_BROADCAST_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    case GET_BROADCAST_CLIENT_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    default:
      return state;
  }
}
