import {
  STRIPE_REPORT,
} from "../../types/types";

export default function stripeReport(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case STRIPE_REPORT:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    default:
      return state;
  }
}
