import { PAYPAL_EARNING_SUMMARY_REQUEST, PAYPAL_EARNING_SUMMARY_SUCCESS, PAYPAL_EARNING_SUMMARY_ERROR } from "../../types/types";
export default function contact(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case PAYPAL_EARNING_SUMMARY_REQUEST:
            return {
                loading: true,
            };
        case PAYPAL_EARNING_SUMMARY_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: payload,
            };
        case PAYPAL_EARNING_SUMMARY_ERROR:
            return {
                ...state,
                loading: false,
                payload: payload,
            };
        default:
            return state;
    }
}